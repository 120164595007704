<template>
  <div>
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <slot name="activator" v-bind:on="on"></slot>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Set new value for {{ getName() }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" md="2" offset-md="4" class="pr-0">
                <v-text-field autofocus type="string" :label="getName()" required v-model="value"></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="pl-0 text-center justify-center">
                <div class="pl-0 pt-5 text-body-1">
                  = {{ value * coefficient }}
                </div>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="pt-7 text-center justify-center">
                <v-btn color="blue darken-1" :loading="loading" :disabled="this.lastValue == this.value"
                       @click="saveValue()">Save
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-progress-linear v-if="loadingHistory" indeterminate color="primary"></v-progress-linear>
                <highcharts v-if="dataIsReady" class="stock" :constructor-type="'stockChart'"
                            :options="stockOptions"></highcharts>
                <div v-else>Empty history.</div>
              </v-col>
            </v-row>

            <!--          <v-row>-->

            <!--            <v-col cols="12" sm="6" md="4">-->
            <!--              <v-select-->
            <!--                  :items="allUsers"-->
            <!--                  hide-details-->
            <!--                  label="Who add"-->
            <!--                  dense-->
            <!--                  return-object-->
            <!--                  v-model="formWhoAdd"-->
            <!--              ></v-select>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="6" md="2">-->
            <!--              <v-combobox-->
            <!--                  :items="['10', '20']"-->
            <!--                  label="%"-->
            <!--                  dense-->
            <!--                  hide-details-->
            <!--                  v-model="formWhoAddPercent"-->
            <!--              ></v-combobox>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="6" md="4">-->
            <!--              <v-select-->
            <!--                  :items="allUsers"-->
            <!--                  hide-details-->
            <!--                  label="Creator"-->
            <!--                  dense-->
            <!--                  v-model="formCreator"-->
            <!--              ></v-select>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="6" md="2">-->
            <!--              <v-combobox-->
            <!--                  :items="['10', '20']"-->
            <!--                  label="%"-->
            <!--                  hide-details-->
            <!--                  dense-->
            <!--                  v-model="formCreatorPercent"-->
            <!--              ></v-combobox>-->
            <!--            </v-col>-->

            <!--            <v-col cols="12" sm="6" md="6">-->
            <!--              <v-autocomplete-->
            <!--                  :items="cabs"-->
            <!--                  item-text="name"-->
            <!--                  dense-->
            <!--                  label="Cab"-->
            <!--                  clearable-->
            <!--                  required-->
            <!--              >-->
            <!--                <template v-slot:selection="{item}">-->
            <!--                  {{item.name + ' - ' + item.id}}-->
            <!--                </template>-->

            <!--                <template v-slot:item="{item}">-->
            <!--                  {{item.name + ' - ' + item.id}}-->
            <!--                </template>-->
            <!--              </v-autocomplete>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="6" md="2">-->
            <!--              <v-autocomplete-->
            <!--                  :items="allRegions"-->
            <!--                  label="Region"-->
            <!--                  dense-->
            <!--                  clearable-->
            <!--              ></v-autocomplete>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="6" md="6">-->
            <!--              <v-autocomplete-->
            <!--                  :disabled="newTypeOffer"-->
            <!--                  :items="type_offers"-->
            <!--                  label="Type offer"-->
            <!--                  hide-details-->
            <!--                  clearable-->
            <!--              ></v-autocomplete>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="6" md="1">-->
            <!--              <v-switch v-model="newTypeOffer"></v-switch>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="6" md="5">-->
            <!--              <v-text-field label="New type offer" :disabled="!newTypeOffer"></v-text-field>-->
            <!--            </v-col>-->


            <!--            &lt;!&ndash;              <v-col cols="12" sm="6" md="2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                <v-combobox&ndash;&gt;-->
            <!--            &lt;!&ndash;                    :items="['0','400', '500', '550']"&ndash;&gt;-->
            <!--            &lt;!&ndash;                    label="Access"&ndash;&gt;-->
            <!--            &lt;!&ndash;                    hide-details&ndash;&gt;-->
            <!--            &lt;!&ndash;                    dense&ndash;&gt;-->
            <!--            &lt;!&ndash;                ></v-combobox>&ndash;&gt;-->
            <!--            &lt;!&ndash;              </v-col>&ndash;&gt;-->

            <!--            <v-col cols="12" sm="12" md="2">-->
            <!--              <v-checkbox v-model="isParenOffer" label="Parent offer"></v-checkbox>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="12" md="5">-->
            <!--              <v-text-field :disabled="!isParenOffer" label="Short name" hint="If this offer is parent" required-->
            <!--                            persistent-hint-->
            <!--              ></v-text-field>-->
            <!--            </v-col>-->

            <!--            <v-col cols="12" sm="12" md="5">-->
            <!--              <v-autocomplete-->
            <!--                  :disabled="isParenOffer"-->
            <!--                  :items="parent_offers"-->
            <!--                  hint="this offer is children of this selected offer" required persistent-hint-->
            <!--                  label="Parent offer"-->
            <!--                  clearable-->
            <!--              ></v-autocomplete>-->
            <!--            </v-col>-->


            <!--            <v-col cols="12" sm="6" md="12">-->
            <!--              <v-text-field label="URL" hint="URL for teasers" required persistent-hint></v-text-field>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="2" md="2">-->
            <!--              <v-text-field label="Campaign ID"></v-text-field>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="12" md="10">-->
            <!--              <v-text-field label="Name" hint="For Email report" required persistent-hint-->
            <!--              ></v-text-field>-->
            <!--            </v-col>-->

            <!--            &lt;!&ndash;              <v-col cols="12" sm="12" md="10" offset="2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                <v-text-field label="Full Name" hint="Name in Mgid cab" required persistent-hint&ndash;&gt;-->
            <!--            &lt;!&ndash;                ></v-text-field>&ndash;&gt;-->
            <!--            &lt;!&ndash;              </v-col>&ndash;&gt;-->


            <!--            <v-col cols="12" sm="6" md="3">-->
            <!--              <v-text-field-->
            <!--                  label="Payment, $"-->
            <!--                  hint="From affiliate Network"-->
            <!--                  persistent-hint-->
            <!--                  required-->
            <!--              ></v-text-field>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="4" md="2" class="pr-0">-->
            <!--              <v-text-field label="Start CPC" required v-model="startCPC"></v-text-field>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="6" md="1" class="pl-0 text-center justify-center">-->
            <!--              <div class="pl-0 pt-5 text-body-1">-->
            <!--                = {{startCPC*10}}-->
            <!--              </div>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="4" md="2" class="pr-0">-->
            <!--              <v-text-field label="Max CPC" required v-model="maxCPC"></v-text-field>-->
            <!--            </v-col>-->
            <!--            <v-col cols="12" sm="6" md="1" class="pl-0 text-center justify-center">-->
            <!--              <div class="pl-0 pt-5 text-body-1">-->
            <!--                = {{maxCPC*10}}-->
            <!--              </div>-->
            <!--            </v-col>-->

            <!--            <v-col cols="12" sm="6" md="3">-->
            <!--              <v-switch-->
            <!--                  color="red"-->
            <!--                  v-model="actionOnly"-->
            <!--                  label="only - 'Action'"-->
            <!--              ></v-switch>-->
            <!--            </v-col>-->
            <!--          </v-row>-->
          </v-container>
          <!--          <small>*indicates required field</small>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--        <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>-->
          <!--        <v-btn color="blue darken-1" disabled text @click="isVisibleOptimizationAddItemWindowShow = false">Moderate-->
          <!--        </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Update value error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMsg }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'ValueEditor',
  props: ['campaignId', 'name'],
  data: () => ({
    dialog: false,
    errorDialog: false,
    errorMsg: '',
    value: 0,
    lastValue: 0,
    coefficient: 10,
    dataIsReady: false,
    loadingHistory: false,
    loading: false,
    stockOptions: {
      rangeSelector: {
        selected: 1
      },
      title: {
        text: 'History of'
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: true
        },
        // series: {
        //   label: {
        //     connectorAllowed: false
        //   },
        //   pointStart: 2010
        // }
      },

      // navigator: {
      //   series: {
      //     label: {
      //       enabled: false
      //     }
      //   }
      // },

      // tooltip: {
      //   // crosshairs: true,
      //   // shared: true
      //   valueDecimals: 2
      // },
      series: [{
        name: 'history',
        // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
        data: [],
        // pointStart: Date.UTC(2018, 1, 1),
        pointInterval: 1000 * 3600 * 24,
        // pointInterval: 3600 * 24,
        tooltip: {
          valueDecimals: 1
        },
        // label: {
        //   connectorAllowed: false
        // },
      }],

      // responsive: {
      //   rules: [{
      //     condition: {
      //       maxWidth: 900
      //     },
      //     chartOptions: {
      //       legend: {
      //         layout: 'horizontal',
      //         align: 'center',
      //         verticalAlign: 'bottom'
      //       }
      //     }
      //   }]
      // }
    }

  }),

  created () {
    if (this.name === 'payment') {
      this.coefficient = 100
    }

    if (this.name === 'manual_margin') {
      this.coefficient = 1
    }

    // try {
    //
    //   const res = await
    //
    //   this.dialog = false
    //   this.$emit('update')
    //
    // } catch (e) {
    //
    // }
  },

  mounted () {
    this.stockOptions.title.text = 'History of ' + this.getName()
    this.stockOptions.series[0].name = this.getName()
  },

  methods: {
    getName () {
      switch (this.name) {
        case 'payment':
          return 'Payment, $'
        case 'max_cpc':
          return 'Max CPC, ¢'
        case 'manual_cpc':
          return 'Manual CPC, ¢'
        case 'manual_margin':
          return 'Manual margin, %'
      }
    },

    async saveValue () {
      this.loading = true

      try {
        const req = {
          campaign_id: this.campaignId,
          type: 'number',
          name: this.name,
          value: this.value * this.coefficient
        }

        const res = await api.updateValue(req)

        // window.console.log('saveValue res', res)

        if (res.data.error) {
          window.console.error('saveValue error', res)
          this.loading = false
          this.errorMsg = res.data.message
          this.errorDialog = true
          return
        }

        // this.dialog = false

        // this.fetchHistory()

        this.$emit('update')
        this.loading = false
        this.dialog = false

      } catch (e) {
        window.console.log('saveValue error', e)

        this.$emit('update')
        this.loading = false
        // this.dialog = false
      }

    },

    fetchHistory () {
      this.loadingHistory = true

      const req = {
        campaign_id: this.campaignId,
        typeOfField: 'number',
        name: this.name
      }

      api.getHistoryOfFieldForCampaign(req).then((res) => {
        if (res.data.error) {
          window.console.log('getHistoryOfFieldForCampaign error', res)
          this.errorMsg = res.data.msg
          this.errorDialog = true
          return
        }
        // window.console.log('saveValue', res)
        this.stockOptions.series[0].data = res.data.history.map(([date, value]) => [date, value / this.coefficient])

        this.loadingHistory = false

        if (res.data.history.length > 0) {
          this.lastValue = this.value = res.data.history[res.data.history.length - 1][1] / this.coefficient
          this.dataIsReady = true
        } else {
          this.dataIsReady = false
        }

      }).catch((e) => {
        this.loadingHistory = false
        window.console.log('getHistoryOfFieldForCampaign error', e)
      })
    }
  },

  watch: {
    dialog (value) {
      if (value) {
        this.fetchHistory()
        // setTimeout(() => {
        //   this.$nextTick(() => {
        //     this.dataIsReady = true
        //   })
        // }, 200)
        // window.console.log('ValueEditor created', this.campaignId, this.name)
      } else {
        this.dataIsReady = false
      }
    }
  }
}
</script>

<style scoped>

</style>