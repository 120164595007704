<template>
  <v-container fluid>
    <v-row
        justify="start"
        align="start"
        width="100%"
        class="pl-3 pr-4 pt-3"
    >
      <v-row>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              :items="whoAddeds"
              hide-details
              label="Who added"
              dense
              solo
              :value="whoAdded"
              @input="whoAdded = $event"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              :items="creators"
              hide-details
              label="Creator"
              dense
              solo
              :value="creator"
              @input="creator = $event"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" md="1">
          <v-text-field
              v-model="campaignId"
              dense
              hide-details
              label="Campaign ID"
              type="string"
              clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-text-field
              v-model="name"
              dense
              hide-details
              label="Name"
              type="string"
              clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              v-model="region"
              :items="regions"
              dense
              filled
              label="Region"
              solo
              clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-autocomplete
              v-model="type_offer"
              :items="type_offers"
              dense
              filled
              label="Type offer"
              solo
              clearable
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-pagination v-model="page" :length="pageCount" total-visible="7"></v-pagination>
        </v-col>

        <v-col cols="12" sm="1" md="1">
          <v-select
              class="pt-1 mr-0"
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              hide-details
              dense
              solo
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" class="pt-0">
          <v-data-table
              v-model="selected"
              fixed-header
              :headers="headers"
              :items="campaigns"
              :expanded="expanded"
              :loading="loading"
              :server-items-length="total"
              :options.sync="options"
              :page.sync="page"
              item-key="campaign_id"
              @page-count="pageCount = $event"
              hide-default-footer
              @click:row="onExpand"
              single-expand
              :show-select="optimizationTableMassActionEnableStatus"
              :selectable-key="'isReadyModeration'"
          >

            <template v-if="itemsPerPage > 10" v-slot:footer>
              <v-row
                  justify="start"
                  align="start"
                  width="100%"
                  class="pl-3 pr-4"
              >
                <v-row>
                  <v-col cols="12" sm="6" offset-md="9" md="3">
                    <v-pagination v-model="page" :length="pageCount" total-visible="7"></v-pagination>
                  </v-col>
                </v-row>
              </v-row>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="text-center pt-2">
                <!--                External links for {{ item.name }}-->


                <a target="_blank" rel="noopener noreferrer" class="pr-3"
                   :href=getPeerClickUrl(item.link)
                >
                  <img src="@/assets/peerclick-icon.png" height="18px">
                </a>

                <a target="_blank" rel="noopener noreferrer" class="pr-3"
                   :href="`https://admin.mgid.com/cab/goodhits/campaigns-edit/id/${item.campaign_id}/`"
                >
                  <img src="@/assets/edit.png" height="18px">
                </a>

                <a target="_blank" rel="noopener noreferrer" class="pr-3"
                   :href="`https://admin.mgid.com/cab/goodhits/ghits/campaign_id/${item.campaign_id}/status/approved`"
                >
                  <img src="@/assets/icon-goods.gif" height="18px">
                </a>

                <a target="_blank" rel="noopener noreferrer" class="pr-3"
                   :href="`https://dashboard.mgid.com/advertisers/teasers-goods/campaign_id/${item.campaign_id}/`"
                >
                  <img src="@/assets/icon_teaser.svg" height="18px">
                </a>

                <a target="_blank" rel="noopener noreferrer" class="pr-3"
                   :href="`https://dashboard.mgid.com/advertisers/daily-stat/type/goods/id/${item.campaign_id}`"
                >
                  <img src="@/assets/icon_statistics.svg" height="18px">
                </a>

                <a target="_blank" rel="noopener noreferrer" class="pr-3"
                   :href="`https://dashboard.mgid.com/advertisers/edit/campaign_id/${item.campaign_id}`"
                >
                  <img src="@/assets/icon_settings.svg" height="18px">
                </a>

                <a target="_blank" rel="noopener noreferrer" class="pr-3"
                   :href="`https://dashboard.mgid.com/advertisers/campaign-quality-analysis/id/${item.campaign_id}`"
                >
                  <img src="@/assets/icon_selective_bidding.svg" height="18px">
                </a>

              </td>
            </template>


            <!--          <template v-slot:top>-->
            <!--            <v-toolbar flat>-->
            <!--              <v-toolbar-title>Optimization Table</v-toolbar-title>-->
            <!--              <v-spacer></v-spacer>-->
            <!--              <v-switch v-model="singleExpand" label="Single expand" class="mt-2"></v-switch>-->
            <!--            </v-toolbar>-->
            <!--          </template>-->

            <template v-slot:item.creator="{ item }">
              <div v-if="item.creator !== 'unknown'">
                {{ item.creator }}
              </div>

            </template>

            <!--            <template v-slot:item.creator="{ item }">-->
            <!--              {{ item.creator }} ( {{ item.creator_proc }} )-->
            <!--            </template>-->

            <template v-slot:item.optimization_date="{ item }">
              {{ timeConverter(item.optimization_date) }}
            </template>

            <template v-slot:item.campaign_id="{ item }">
              <div v-if="onModeration">
                <v-hover
                    v-if="!item.isReadyModeration"
                    v-slot:default="{ hover }">

                  <div
                      :class="{'text-decoration-underline': hover}"
                      :style="{'cursor': hover ? 'pointer': 'default'}"
                      @click.stop="clickOnCampaignId(item)"
                  >{{ item.campaign_id }}
                  </div>
                </v-hover>

                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on }">
                    <v-hover v-slot:default="{ hover }">
                      <div
                          v-on="on"
                          :class="{'text-decoration-underline': hover}"
                          :style="{'cursor': hover ? 'pointer': 'default'}"
                          @click.stop="clickOnCampaignId(item)"
                          class="green lighten-1"
                      >{{ item.campaign_id }}
                      </div>
                    </v-hover>
                  </template>
                  <span>Ready for optimization</span>
                </v-tooltip>
              </div>
              <div v-else>

                <v-tooltip bottom v-if="item.stas_optimized === 0">
                  <template v-slot:activator="{ on }">
                    <v-hover v-slot:default="{ hover }">
                      <div
                          v-on="on"
                          :class="{'text-decoration-underline': hover}"
                          :style="{'cursor': hover ? 'pointer': 'default'}"
                          @click.stop="clickOnCampaignId(item)"
                          class="yellow lighten-3"
                      >{{ item.campaign_id }}
                      </div>
                    </v-hover>
                  </template>
                  <span>Wait Optimize</span>
                </v-tooltip>

                <v-hover
                    v-else-if="item.opti_off === 0"
                    v-slot:default="{ hover }">
                  <div
                      :class="{'text-decoration-underline': hover}"
                      :style="{'cursor': hover ? 'pointer': 'default'}"
                      @click.stop="clickOnCampaignId(item)"
                  >{{ item.campaign_id }}
                  </div>
                </v-hover>

                <v-tooltip bottom v-else-if="item.opti_off === 1">
                  <template v-slot:activator="{ on }">
                    <v-hover v-slot:default="{ hover }">
                      <div
                          v-on="on"
                          :class="{'text-decoration-underline': hover}"
                          :style="{'cursor': hover ? 'pointer': 'default'}"
                          @click.stop="clickOnCampaignId(item)"
                          class="red lighten-3"
                      >{{ item.campaign_id }}
                      </div>
                    </v-hover>
                  </template>
                  <span>Optimization is OFF</span>
                </v-tooltip>

              </div>


            </template>


            <template v-slot:item.name="{ item }">
              <v-tooltip bottom v-if="item.comments.length > 0">
                <template v-slot:activator="{ on}">
                  <div v-on="on" class="grey lighten-2">{{ item.name }}</div>
                </template>
                <span>{{ item.comments }}</span>
              </v-tooltip>

              <div v-else>
                {{ item.name }}
              </div>
            </template>

            <!--            <template v-slot:item.type_offer="{ item }">-->
            <!--              {{getTypeOfferName(item.type_offer)}}-->
            <!--            </template>-->

            <template v-slot:item.payment="{ item }">
              <div v-if="onModeration || item.stas_optimized === 0">
                {{ item.payment ? item.payment / 100 : '' }}
              </div>
              <value-editor
                  v-else
                  v-slot:activator="{ on: click }" :name="'payment'" :campaign-id="item.campaign_id"
                  @update="getDataFromApi()">
                <v-hover v-slot:default="{ hover }">
                  <div :class="{'text-decoration-underline': hover}"
                       :style="{'cursor': hover ? 'pointer': 'default'}"
                       v-on="{...click}"
                  >
                    {{ item.payment / 100 + (item.new_payment > 0 ? ' (' + item.new_payment / 100 + ' )' : '') }}
                  </div>
                </v-hover>
              </value-editor>
            </template>

            <template v-slot:item.start_cpc="{ item }">

              <div v-if="!item.autoprice_off">{{ (item.start_cpc / 10).toFixed(1) }}</div>

              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on}">
                  <div v-on="on" class="red lighten-3"
                  >{{ (item.start_cpc / 10).toFixed(1) }}
                  </div>
                </template>
                <span>AutoPrice is OFF</span>
              </v-tooltip>

            </template>

            <!--            <template v-slot:item.start_cpc="{ item }">-->
            <!--              {{ (item.start_cpc/10).toFixed(1) }}-->
            <!--            </template>-->

            <template v-slot:item.max_cpc="{ item }">
              <div v-if="onModeration || item.stas_optimized === 0">
                {{ item.max_cpc ? (item.max_cpc / 10).toFixed(1) : '' }}
              </div>
              <value-editor
                  v-else
                  v-slot:activator="{ on: click }" :name="'max_cpc'" :campaign-id="item.campaign_id"
                  @update="getDataFromApi()">
                <div v-if="item.max_cpc">
                  <v-hover v-slot:default="{ hover }">
                    <div :class="{'text-decoration-underline': hover}"
                         :style="{'cursor': hover ? 'pointer': 'default'}"
                         v-on="{...click}">
                      {{ (item.max_cpc / 10).toFixed(1) }}
                    </div>
                  </v-hover>
                </div>
                <div v-else class="hidden">
                  <v-icon v-on="{...click}">mdi-pencil</v-icon>
                </div>
              </value-editor>

            </template>


            <template v-slot:item.manual_cpc="{ item }">
              <div v-if="onModeration || item.stas_optimized === 0">
                {{ item.manual_cpc ? (item.manual_cpc / 10).toFixed(1) : '' }}
              </div>
              <value-editor
                  v-else
                  v-slot:activator="{ on: click }" :name="'manual_cpc'" :campaign-id="item.campaign_id"
                  @update="getDataFromApi()">
                <div v-if="item.manual_cpc !== 0">
                  <v-hover v-slot:default="{ hover }">
                    <div :class="{'text-decoration-underline': hover}"
                         :style="{'cursor': hover ? 'pointer': 'default'}"
                         v-on="{...click}">
                      {{ (item.manual_cpc / 10).toFixed(1) }}
                    </div>
                  </v-hover>
                </div>
                <div v-else class="hidden">
                  <v-icon v-on="{...click}">mdi-pencil</v-icon>
                </div>
              </value-editor>
            </template>

            <!--            <template v-slot:item.manual_cpc="{ item }">-->
            <!--              {{ item.manual_cpc > 0 ? item.manual_cpc : ''}}-->
            <!--            </template>-->

            <template v-slot:item.manual_margin="{ item }">
              <div v-if="onModeration || item.stas_optimized === 0">
                {{ item.manual_margin !== 0 && item.manual_margin !== 72 ? item.manual_margin : '' }}
              </div>
              <value-editor
                  v-else
                  v-slot:activator="{ on: click }" :name="'manual_margin'" :campaign-id="item.campaign_id"
                  @update="getDataFromApi()">
                <div v-if="item.manual_margin !== 0 &&  item.manual_margin !== 72">
                  <v-hover v-slot:default="{ hover }">
                    <div :class="{'text-decoration-underline': hover}"
                         :style="{'cursor': hover ? 'pointer': 'default'}"
                         v-on="{...click}">
                      {{ item.manual_margin }}
                    </div>
                  </v-hover>
                </div>
                <div v-else class="hidden">
                  <v-icon v-on="{...click}">mdi-pencil</v-icon>
                </div>
              </value-editor>
            </template>

            <template v-slot:item.start_approve="{ item }">
              {{ item.start_approve !== 0 ? item.start_approve : '' }}
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-row>

    <v-dialog v-model="campaignEditorDialog" max-width="900px"
              :persistent="isPersistentEditor || formAutopriceOffLoading || formOptiOffLoading || formLoadingCampaignInfo">
      <v-card>
        <v-card-title class="pb-0">
          <span v-if="newForm" class="headline">Add Campaign for optimization</span>
          <span v-else-if="formIsEdit" class="headline">Edit: {{ formCampaignId }} - {{ formName }}</span>
          <span v-else class="headline">{{ formCampaignId }} - {{ formName }}</span>
          <v-spacer></v-spacer>

          <v-tooltip bottom v-if="formIsEdit || !newForm">
            <template v-slot:activator="{ on }">
              <v-btn icon @click="copyDialogConfirm = true" v-on="on" :disabled="formLoadingCampaignInfo">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Copy</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-container class="pt-0">
            <v-tabs right height="22" v-model="formTab">
              <!--              <v-tab>Item One</v-tab>-->
              <!--              <v-tab>Item Two</v-tab>-->
              <!--              <v-tab>Item Three</v-tab>-->

              <v-tab href="#tab-1">
                <v-icon>mdi-information-variant</v-icon>
              </v-tab>

              <v-tab href="#tab-2" :disabled="newForm || formIsEdit || !formStasOptimized">
                <v-icon>mdi-cog-outline</v-icon>
              </v-tab>

            </v-tabs>


            <v-tabs-items v-model="formTab" class="pt-4">
              <v-tab-item value="tab-1">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                        :items="allCreators"
                        hide-details
                        label="Who add"
                        dense
                        return-object
                        :readonly="isReadonlyCreatorAdnWhoAdd()"
                        v-model="formWhoAdd"
                    >
                      <template v-slot:append-outer v-if="formWhoAddNeedUpdate">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :loading="formWhoAddLoading" small icon>
                              <v-icon color="primary" @click="onUpdateWhoAdd()">mdi-content-save-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Update</span>
                        </v-tooltip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                        :items="allCreators"
                        :readonly="isReadonlyCreatorAdnWhoAdd()"
                        hide-details
                        label="Creator"
                        dense
                        v-model="formCreator"
                    >
                      <template v-slot:append-outer v-if="formCreatorNeedUpdate">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :loading="formCreatorLoading" small icon>
                              <v-icon color="primary" @click="onUpdateCreator()">mdi-content-save-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Update</span>
                        </v-tooltip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!--                  <v-col cols="12" sm="6" md="2">-->
                  <!--                    <v-combobox-->
                  <!--                        :items="['0', '10', '20', '40']"-->
                  <!--                        label="%"-->
                  <!--                        hide-details-->
                  <!--                        dense-->
                  <!--                        :readonly="isReadonlyCreatorAdnWhoAdd()"-->
                  <!--                        v-model="formCreatorPercent"-->
                  <!--                    >-->
                  <!--                      <template v-slot:append-outer v-if="formCreatorPercentNeedUpdate">-->
                  <!--                        <v-tooltip bottom>-->
                  <!--                          <template v-slot:activator="{ on }">-->
                  <!--                            <v-btn v-on="on" :loading="formCreatorPercentLoading" small icon>-->
                  <!--                              <v-icon color="primary" @click="onUpdateCreatorPercent()">mdi-content-save-outline-->
                  <!--                              </v-icon>-->
                  <!--                            </v-btn>-->
                  <!--                          </template>-->
                  <!--                          <span>Update</span>-->
                  <!--                        </v-tooltip>-->
                  <!--                      </template>-->

                  <!--                      &lt;!&ndash;                      <template v-slot:append-outer v-if="formCreatorPercentNeedUpdate">&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                        <v-tooltip bottom>&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                          <template v-slot:activator="{ on }">&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                            <v-icon v-on="on" color="primary" @click="onUpdateCreatorPercent()">mdi-content-save-outline&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                            </v-icon>&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                          </template>&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                          <span>Update</span>&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                        </v-tooltip>&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                      </template>&ndash;&gt;-->
                  <!--                    </v-combobox>-->
                  <!--                  </v-col>-->

                  <!--                  <v-col cols="12" sm="6" md="2">-->
                  <!--                    <v-combobox-->
                  <!--                        :items="['0', '10', '20']"-->
                  <!--                        label="%"-->
                  <!--                        dense-->
                  <!--                        :readonly="isReadonlyCreatorAdnWhoAdd()"-->
                  <!--                        hide-details-->
                  <!--                        v-model="formWhoAddPercent"-->
                  <!--                    >-->
                  <!--                      <template v-slot:append-outer v-if="formWhoAddPercentNeedUpdate">-->
                  <!--                        <v-tooltip bottom>-->
                  <!--                          <template v-slot:activator="{ on }">-->
                  <!--                            <v-btn v-on="on" :loading="formWhoAddPercentLoading" small icon>-->
                  <!--                              <v-icon color="primary" @click="onUpdateWhoAddPercent()">mdi-content-save-outline-->
                  <!--                              </v-icon>-->
                  <!--                            </v-btn>-->
                  <!--                          </template>-->
                  <!--                          <span>Update</span>-->
                  <!--                        </v-tooltip>-->
                  <!--                      </template>-->
                  <!--                    </v-combobox>-->
                  <!--                  </v-col>-->
                  <v-col cols="12" sm="5" md="5">
                    <v-autocomplete
                        :items="cabs"
                        item-text="name"
                        single-line dense
                        label="Cab"
                        :clearable="clearableForm()"
                        required
                        :readonly="readonlyFormParam() || readonlyParamCampaignId()"
                        v-model="formCab"
                        return-object
                    >
                      <template v-slot:selection="{item}">
                        {{ item.name + ' - ' + item.id }}
                      </template>

                      <template v-slot:item="{item}">
                        {{ item.name + ' - ' + item.id }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-autocomplete
                        :readonly="readonlyFormParam()"
                        :items="allRegionsClear"
                        label="Region"
                        :clearable="clearableForm()"
                        single-line dense
                        v-model="formRegion"
                    ></v-autocomplete>
                  </v-col>
                  <!--                  <v-col cols="12" sm="2" offset-md="2" md="2" v-if="isAdmin">-->
                  <!--                    <v-combobox-->
                  <!--                        :items="['0','400', '500', '550']"-->
                  <!--                        label="Access"-->
                  <!--                        hide-details-->
                  <!--                        dense-->
                  <!--                        :readonly="readonlyFormParam()"-->
                  <!--                        v-model="formAccess"-->
                  <!--                    ></v-combobox>-->
                  <!--                  </v-col>-->
                  <v-col cols="12" sm="5" md="5">
                    <v-autocomplete
                        :items="all_type_offers"
                        :readonly="readonlyFormParam()"
                        label="Type offer ( 7 )"
                        hide-details
                        :clearable="clearableForm()"
                        single-line dense
                        v-model="formTypeOffer"
                    ></v-autocomplete>
                  </v-col>
                  <!--                  <v-col cols="12" sm="6" md="1">-->
                  <!--                    <v-switch :readonly="readonlyFormParam()" single-line dense v-model="createNewTypeOffer"></v-switch>-->
                  <!--                  </v-col>-->
                  <!--                  <v-col cols="12" sm="6" md="5">-->
                  <!--                    <v-text-field :readonly="readonlyFormParam()" single-line dense label="New type offer"-->
                  <!--                                  :disabled="!createNewTypeOffer"-->
                  <!--                                  v-model="formNewTypeOffer"></v-text-field>-->
                  <!--                  </v-col>-->


                  <!--              <v-col cols="12" sm="2" md="2">-->
                  <!--                <v-checkbox :readonly="readonlyFormParam()" single-line dense v-model="formIsMainOffer"-->
                  <!--                            label="Main offer"></v-checkbox>-->
                  <!--              </v-col>-->

                  <!--              <v-col cols="12" sm="4" md="4">-->
                  <!--                <v-autocomplete-->
                  <!--                    :readonly="readonlyFormParam()"-->
                  <!--                    :disabled="!formIsMainOffer || formIsNewMainOffer"-->
                  <!--                    :items="stas_ofrs"-->
                  <!--                    hint="this offer will is parent of children offer" required persistent-hint-->
                  <!--                    label="Main offer"-->
                  <!--                    :clearable="clearableForm()"-->
                  <!--                    single-line dense-->
                  <!--                    v-model="formMainOffer"-->
                  <!--                ></v-autocomplete>-->
                  <!--              </v-col>-->

                  <!--              <v-col cols="12" sm="2" md="2" v-if="formIsMainOffer">-->
                  <!--                <v-checkbox single-line dense label="New" :readonly="readonlyFormParam()"-->
                  <!--                            v-model="formIsNewMainOffer"></v-checkbox>-->
                  <!--              </v-col>-->

                  <!--              <v-col cols="12" sm="4" md="4" v-if="formIsMainOffer">-->
                  <!--                <v-text-field single-line dense label="Name" hint="new name for Main offer" required persistent-hint-->
                  <!--                              :disabled="!formIsNewMainOffer"-->
                  <!--                              v-model="formNewMainOfferName"-->
                  <!--                              :readonly="readonlyFormParam()"-->
                  <!--                ></v-text-field>-->
                  <!--              </v-col>-->

                  <!--              <v-col cols="12" sm="4" md="4" v-if="!formIsMainOffer">-->
                  <!--                <v-autocomplete-->
                  <!--                    :items="parent_offers"-->
                  <!--                    :readonly="readonlyFormParam()"-->
                  <!--                    hint="this offer is children of this selected offer" required persistent-hint-->
                  <!--                    label="Parent offer"-->
                  <!--                    :clearable="clearableForm()"-->
                  <!--                    single-line dense-->
                  <!--                    v-model="formParentOffer"-->
                  <!--                ></v-autocomplete>-->
                  <!--              </v-col>-->


                  <v-col cols="12" sm="6" md="12">
                    <v-text-field single-line dense label="URL" hint="URL for teasers" required persistent-hint
                                  :disabled="checkingCampaign"
                                  :readonly="readonlyFormParam()"
                                  v-bind:value="formURL"
                                  @input="setURL($event)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="2" md="2">
                    <v-text-field single-line dense label="Campaign ID ( 1 )" v-model="formCampaignId"
                                  :disabled="checkingCampaign"
                                  :readonly="readonlyParamCampaignId() || formIsReadyCampaignInfo"
                                  :rules="[validateCampaignId]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="1" md="1" class="pt-5 " v-if="!formIsReadyCampaignInfo">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                              icon :loading="formLoadingCampaignInfo" :disabled="!readyFetchCampaignInfo()"
                              @click="getCampaignInfoFromCab()">
                            <v-icon color="primary">mdi-refresh</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <div>
                        <span v-if="!readyFetchCampaignInfo()">Select Cab and fill in Campaign ID</span>
                        <span v-else>Get info for Campaign ID</span>
                      </div>
                    </v-tooltip>
                  </v-col>

                  <v-col cols="12" sm="1" md="1" class="pt-5 " v-if="formIsReadyCampaignInfo">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn icon @click="resetFormCampaignId()">
                            <v-icon color="primary">mdi-lock-reset</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>Reset</span>
                    </v-tooltip>

                  </v-col>

                  <v-col cols="12" sm="12" md="9">
                    <v-text-field single-line dense label="Full name" hint="name from cab" required persistent-hint
                                  v-model="formFullName"
                                  readonly
                    >
                      <template v-slot:append-outer v-if="formFullNameNeedUpdate">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary" @click="onUpdateFullName()">mdi-content-save-outline
                            </v-icon>
                          </template>
                          <span>Update</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="10" md="3">
                    <v-tooltip bottom v-if="formIsReadyCampaignInfo">
                      <template v-slot:activator="{ on }">
                        <v-icon v-if="formCampaignStatusName === 'paused'" v-on="on" color="red">
                          mdi-pause-circle-outline
                        </v-icon>
                        <v-icon v-else-if="formCampaignStatusName === 'active'" v-on="on" color="green">
                          mdi-play-circle-outline
                        </v-icon>
                        <v-icon v-else v-on="on">mdi-alert-decagram-outline v-on="on"</v-icon>
                      </template>
                      <div>
                        <span v-if="formCampaignStatusName === 'active'">Active</span>
                        <span v-else>{{ formCampaignStatusDescription }}</span>
                      </div>
                    </v-tooltip>

                    <v-tooltip bottom v-if="formIsReadyCampaignInfo && formCampaignInfo.campaignType === 'push'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-chevron-triple-down
                        </v-icon>
                      </template>
                      <span>PUSH Campaign</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="formIsReadyCampaignInfo && formCampaignInfo.campaignType === 'product'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-cart-outline
                        </v-icon>
                      </template>
                      <span>Product Campaign</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="formIsReadyCampaignInfo">
                      <template v-slot:activator="{ on, attrs }">
                        <v-badge
                            :color="formTeasersCount=== 0 ? 'error' : 'primary'"
                            :content="formTeasersCount"
                            overlap
                        >
                          <v-icon v-bind="attrs" v-on="on">mdi-card-text-outline</v-icon>
                        </v-badge>
                      </template>
                      <span>Teasers</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="formIsReadyCampaignInfo">
                      <template v-slot:activator="{ on, attrs }">
                        <v-badge
                            color="primary"
                            :content="getCampaignLimits()"
                            overlap
                        >
                          <v-icon class="pl-3" v-bind="attrs" v-on="on">mdi-cash</v-icon>
                        </v-badge>
                      </template>
                      <span>Daily budget</span>
                    </v-tooltip>

                    <div class="pt-4">
                      <a target="_blank" rel="noopener noreferrer" class="pr-3"
                         :href="`https://admin.mgid.com/cab/goodhits/campaigns-edit/id/${formCampaignId}/`"
                      >
                        <img src="@/assets/edit.png" height="18px">
                      </a>

                      <a target="_blank" rel="noopener noreferrer" class="pr-3"
                         :href="`https://admin.mgid.com/cab/goodhits/ghits/campaign_id/${formCampaignId}/status/approved`"
                      >
                        <img src="@/assets/icon-goods.gif" height="18px">
                      </a>

                      <a target="_blank" rel="noopener noreferrer" class="pr-3"
                         :href="`https://dashboard.mgid.com/advertisers/teasers-goods/campaign_id/${formCampaignId}/`"
                      >
                        <img src="@/assets/icon_teaser.svg" height="18px">
                      </a>

                      <a target="_blank" rel="noopener noreferrer" class="pr-3"
                         :href="`https://dashboard.mgid.com/advertisers/daily-stat/type/goods/id/${formCampaignId}`"
                      >
                        <img src="@/assets/icon_statistics.svg" height="18px">
                      </a>

                      <a target="_blank" rel="noopener noreferrer" class="pr-3"
                         :href="`https://dashboard.mgid.com/advertisers/edit/campaign_id/${formCampaignId}`"
                      >
                        <img src="@/assets/icon_settings.svg" height="18px">
                      </a>

                      <a target="_blank" rel="noopener noreferrer" class="pr-3"
                         :href="`https://dashboard.mgid.com/advertisers/campaign-quality-analysis/id/${formCampaignId}`"
                      >
                        <img src="@/assets/icon_selective_bidding.svg" height="18px">
                      </a>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="10" md="9">
                    <v-text-field single-line dense label="Short name"
                                  hint="for Analyzes Data report with short name Network(PP)" required
                                  persistent-hint
                                  v-model="formName"
                                  :readonly="readonlyFormName()"
                    >
                      <template v-slot:append-outer v-if="formNameNeedUpdate">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :loading="formNameLoading" small icon>
                              <v-icon color="primary" @click="onUpdateName()">mdi-content-save-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Update</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="10" offset-md="3" md="9">
                    <v-text-field single-line dense label="Comments" required v-model="formComments"
                                  :readonly="readonlyFormParam()"
                    ></v-text-field>
                  </v-col>


                  <!--              <v-col cols="12" sm="12" md="10" offset="2">-->
                  <!--                <v-text-field label="Full Name" hint="Name in Mgid cab" required persistent-hint-->
                  <!--                ></v-text-field>-->
                  <!--              </v-col>-->


                  <v-col cols="12" sm="2" md="2">
                    <v-text-field
                        label="Payment, $"
                        hint="From affiliate Network"
                        persistent-hint
                        required
                        v-model="formPayment"
                        :readonly="readonlyFormParam()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="">
                    <v-text-field label="Start CPC, ¢" required v-model="formStartCPC"
                                  :disabled="checkingCampaign"
                                  :readonly="readonlyFormParam()"
                                  persistent-hint
                    ></v-text-field>
                  </v-col>
                  <!--                  <v-col cols="12" sm="2" md="1" class="pl-0 text-center justify-center">-->
                  <!--                    <div class="pl-0 pt-5 text-body-1">-->
                  <!--                      = {{Math.round((formStartCPC*10).toFixed(2))}}-->
                  <!--                    </div>-->
                  <!--                  </v-col>-->
                  <v-col cols="12" sm="2" md="2" class="">
                    <v-text-field label="Max CPC, ¢" required v-model="formMaxCPC"
                                  :rules="[validateMaxCPC]"
                                  :readonly="readonlyFormParam()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="2" md="2" class="">
                    <v-text-field label="Max Coef" required v-model="formMaxCoef"
                                  :rules="[validateMaxCoef]"
                                  :readonly="readonlyFormParam()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1" class="text-center justify-center">
                    <v-switch
                        color="red"
                        v-model="formEnableStartApprove"
                        :readonly="readonlyFormParam()"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="text-center justify-center">
                    <v-text-field label="Start approve, %" required v-model="formStartApprove"
                                  :rules="[validateStartApprove]"
                                  type="number"
                                  :readonly="readonlyFormParam()"
                                  :disabled="!formEnableStartApprove"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="" md="3">
                    <v-switch
                        color="red"
                        class="pl-3"
                        v-model="formActionOnly"
                        :readonly="readonlyFormParam()"
                        label="only - 'Action'"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-tabs vertical>
                  <!--              <v-tab>Item One</v-tab>-->
                  <!--              <v-tab>Item Two</v-tab>-->
                  <!--              <v-tab>Item Three</v-tab>-->

                  <v-tab>
                    Opti Off
                    <!--                    <v-icon>mdi-cog-outline</v-icon>-->
                  </v-tab>

                  <v-tab>
                    Autoprice Off
                  </v-tab>


                  <v-tab-item>
                    <v-card flat>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" offset-sm="3" offset-md="3">
                          <v-row>
                            <v-col cols="12" sm="3" md="3">
                              <v-switch color="red"
                                        v-model="formOptiOff"
                                        inset
                                        :loading="formOptiOffLoading"
                                        @change="onChangeFormOptiOff"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="9" md="9" class="pt-7">
                              <span class="subtitle-1">Optimization Off</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-progress-linear v-if="!dataIsReady" indeterminate color="primary"></v-progress-linear>
                        <highcharts v-if="dataIsReady" class="stock" :constructor-type="'stockChart'"
                                    :options="stockOptionsOptiOff"></highcharts>
                      </v-row>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card flat>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" offset-sm="3" offset-md="3">
                          <v-row>
                            <v-col cols="12" sm="3" md="3">
                              <v-switch color="red"
                                        v-model="formAutopriceOff"
                                        inset
                                        :loading="formAutopriceOffLoading"
                                        @change="onChangeFormAutopriceOff"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="9" md="9" class="pt-7">
                              <span class="subtitle-1">Auto-price Off</span>
                            </v-col>
                          </v-row>

                        </v-col>
                      </v-row>
                      <v-row>
                        <v-progress-linear v-if="!dataIsReady" indeterminate color="primary"></v-progress-linear>
                        <highcharts v-if="dataIsReady" class="stock" :constructor-type="'stockChart'"
                                    :options="stockOptionsAutopriceOff"></highcharts>
                      </v-row>
                    </v-card>
                  </v-tab-item>


                </v-tabs>
                <!--                <v-row>-->
                <!--                  <v-col cols="12" sm="6" md="6">-->
                <!--                    <v-row>-->
                <!--                      <v-col cols="12" sm="3" md="3">-->
                <!--                        <v-switch color="red"-->
                <!--                                  v-model="formOptiOff"-->
                <!--                                  inset-->
                <!--                                  :loading="formOptiOffLoading"-->
                <!--                                  @change="onChangeFormOptiOff"-->
                <!--                        ></v-switch>-->
                <!--                      </v-col>-->
                <!--                      <v-col cols="12" sm="9" md="9" class="pt-7">-->
                <!--                        <span class="subtitle-1">Optimization Off</span>-->
                <!--                      </v-col>-->
                <!--                    </v-row>-->
                <!--                  </v-col>-->


                <!--                  <v-col cols="12" sm="6" md="6">-->
                <!--                    <v-row>-->
                <!--                      <v-col cols="12" sm="3" md="3">-->
                <!--                        <v-switch color="red"-->
                <!--                                  v-model="formAutopriceOff"-->
                <!--                                  inset-->
                <!--                                  :loading="formAutopriceOffLoading"-->
                <!--                                  @change="onChangeFormAutopriceOff"-->
                <!--                        ></v-switch>-->
                <!--                      </v-col>-->
                <!--                      <v-col cols="12" sm="9" md="9" class="pt-7">-->
                <!--                        <span class="subtitle-1">Auto-price Off</span>-->
                <!--                      </v-col>-->
                <!--                    </v-row>-->

                <!--                  </v-col>-->
                <!--                </v-row>-->
              </v-tab-item>
            </v-tabs-items>

          </v-container>

          <!--          <small>*indicates required field</small>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="isPersistentEditor" color="blue darken-1" text @click="campaignEditorDialog = false">Cancel
          </v-btn>
          <v-btn v-if="newForm || ( formIsEdit && (isAdmin || !formIsReadyModeration || allowEditCreatorAndWhoAdd) )" color="blue darken-1"
                 :disabled="!isAllowCheckCampaign() || checkingCampaign || campaignIsValid"
                 @click="checkCampaign()"
                 :loading="checkingCampaign"
          >Check Campaign
            <v-icon>mdi-arrow-decision-outline</v-icon>
          </v-btn>

          <v-btn v-if="newForm" color="green darken-1" :disabled="!campaignIsValid" @click="addNewCampaign()">Send to
            Moderation
            <v-icon>mdi-send</v-icon>
          </v-btn>

          <v-btn v-if="formIsEdit && formIsReadyModeration" color="red lighten-2"
                 @click="cancelModeration()">
            Cancel moderation
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
          <v-btn v-else-if="!newForm && formIsEdit" color="green darken-1" :disabled="!campaignIsValid"
                 @click="moderationCampaign()">
            Send to Moderation
            <v-icon>mdi-send</v-icon>
          </v-btn>

          <v-btn v-if="formIsEdit && (isAdmin || allowEditCreatorAndWhoAdd)" color="blue darken-1"
                 :disabled="!campaignIsValid">
            SAVE
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>

          <v-btn v-if="formIsEdit && (isAdmin || allowEditCreatorAndWhoAdd)" color="green darken-1" :disabled="!formIsReadyModeration"
                 @click="onOptimizeCampaign">
            Optimize
            <v-icon>mdi-airplane-takeoff</v-icon>
          </v-btn>

          <!--          <v-btn color="blue darken-1" v-if="isAllowModerate() && (newForm || formIsEdit)"-->
          <!--                 @click="isVisibleOptimizationAddItemWindowShow = false">Moderate-->
          <!--          </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isVisibleValidationErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Check Campaign for optimization Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ campaignValidationErrorMsg }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="copyDialogConfirm" max-width="350px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Copy Campaign ?</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-btn color="green darken-1" @click="confirmCopyCampaign()">Yes
                  <v-icon>mdi-check-bold</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-btn color="primary" @click="copyDialogConfirm = false">Cancel
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="massActionsDialog" max-width="850px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Mass actions</span>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-3">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="1" align-self="center">
                <v-switch label="Yes" v-model="confirmMassActions" color="red"></v-switch>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <!--              <v-col cols="12" sm="4" align-self="center">-->
              <!--                <v-btn color="red lighten-2" @click="massCancelModeration()"-->
              <!--                :disabled="!confirmMassActions">-->
              <!--                  Cancel moderation-->
              <!--                  <v-icon>mdi-cancel</v-icon>-->
              <!--                </v-btn>-->

              <!--              </v-col>-->
              <!--              <v-col cols="12" sm="4" align-self="center">-->
              <!--                <v-btn color="green darken-1" @click="massModerationCampaign()"-->
              <!--                       :disabled="!confirmMassActions">-->
              <!--                  Send to Moderation-->
              <!--                  <v-icon>mdi-send</v-icon>-->
              <!--                </v-btn>-->
              <!--              </v-col>-->
              <v-col cols="12" sm="4" v-if="isAdmin || allowEditCreatorAndWhoAdd" class="d-flex justify-center">
                <v-btn color="green darken-1" @click="massOptimizeCampaign"
                       :disabled="!confirmMassActions">
                  Optimize
                  <v-icon>mdi-airplane-takeoff</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <EditorCampaign v-if="optimizationAddNewTask"
                    v-model="optimizationAddNewTask"
                    :create-new-task="true"
                    :all-cabs="cabs"
                    :all-type-offers="all_type_offers"
                    :all-who-addeds="allWhoAddeds"
                    :all-creators="allCreators"
                    :all-regions="allRegionsClear"
                    :aff-networks="affNetworks">
    </EditorCampaign>

  </v-container>

</template>

<script>
import api from '@/utils/api'
import Vue from 'vue'
import {
  IFACE_CAB_ITEMS,
  IFACE_DRAWER_RIGHT_ON, IFACE_DST_SET_DEP, IFACE_DST_SET_DEPS, IFACE_ITEMS_PER_PAGE,
  IFACE_OPTIMIZATION_ADD_ITEM_WINDOW_OFF,
  IFACE_OPTIMIZATION_ADD_ITEM_WINDOW_ON, IFACE_OPTIMIZATION_ADD_NEW_TASK_OFF, IFACE_OPTIMIZATION_ADD_NEW_TASK_ON,
  IFACE_OPTIMIZATION_ON_MODERATION_OFF,
  IFACE_OPTIMIZATION_ON_MODERATION_ON,
  IFACE_OPTIMIZATION_REFRESH_OFF,
  IFACE_OPTIMIZATION_REFRESH_ON, IFACE_OPTIMIZATION_TABLE_LOADING_OFF, IFACE_OPTIMIZATION_TABLE_LOADING_ON,
} from '@/store/actions/iface'

import ValueEditor from './ValueEditor'
import EditorCampaign from './EditorCampaign'

import { encode } from 'js-base64'
import { DateTime } from 'luxon'
import {
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_OFF,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ON
} from '../../store/actions/iface'

export default {
  name: 'Campaigns',
  components: {ValueEditor, EditorCampaign},
  data: () => ({

    dataIsReady: false,

    selected: [],

    stockOptionsOptiOff: {
      rangeSelector: {
        selected: 1
      },
      title: {
        text: 'History of'
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: true
        },
        // series: {
        //   label: {
        //     connectorAllowed: false
        //   },
        //   pointStart: 2010
        // }
      },

      // navigator: {
      //   series: {
      //     label: {
      //       enabled: false
      //     }
      //   }
      // },

      // tooltip: {
      //   // crosshairs: true,
      //   // shared: true
      //   valueDecimals: 2
      // },
      series: [{
        name: 'history',
        // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
        data: [],
        // pointStart: Date.UTC(2018, 1, 1),
        pointInterval: 1000 * 3600 * 24,
        // pointInterval: 3600 * 24,
        tooltip: {
          valueDecimals: 1
        },
        // label: {
        //   connectorAllowed: false
        // },
      }],

      // responsive: {
      //   rules: [{
      //     condition: {
      //       maxWidth: 900
      //     },
      //     chartOptions: {
      //       legend: {
      //         layout: 'horizontal',
      //         align: 'center',
      //         verticalAlign: 'bottom'
      //       }
      //     }
      //   }]
      // }
    },

    stockOptionsAutopriceOff: {
      rangeSelector: {
        selected: 1
      },
      title: {
        text: 'History of'
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: true
        },
        // series: {
        //   label: {
        //     connectorAllowed: false
        //   },
        //   pointStart: 2010
        // }
      },

      // navigator: {
      //   series: {
      //     label: {
      //       enabled: false
      //     }
      //   }
      // },

      // tooltip: {
      //   // crosshairs: true,
      //   // shared: true
      //   valueDecimals: 2
      // },
      series: [{
        name: 'history',
        // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
        data: [],
        // pointStart: Date.UTC(2018, 1, 1),
        pointInterval: 1000 * 3600 * 24,
        // pointInterval: 3600 * 24,
        tooltip: {
          valueDecimals: 1
        },
        // label: {
        //   connectorAllowed: false
        // },
      }],

      // responsive: {
      //   rules: [{
      //     condition: {
      //       maxWidth: 900
      //     },
      //     chartOptions: {
      //       legend: {
      //         layout: 'horizontal',
      //         align: 'center',
      //         verticalAlign: 'bottom'
      //       }
      //     }
      //   }]
      // }
    },

    firstRun: true,
    tableHideDefaultFooter: true,
    campaignEditorDialog: false,
    isPersistentEditor: false,
    campaignId: null,

    itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
    page: 1,
    // itemsPerPage: 5,
    pageCount: 0,
    options: {itemsPerPage: 10},
    total: 0,
    expanded: [],
    singleExpand: false,
    campaigns: [],
    headers: [],
    // loading: false,
    name: null,
    whoAdded: 'All',
    creator: 'All',
    // user: 'All',

    allCreatorsInfo: [],
    allWhoAddeds: [],

    creators: [],
    whoAddeds: [],

    region: undefined,
    regions: [],
    allRegions: [],
    allRegionsClear: [],
    allCreators: [],
    // parent_offers: [],
    // stas_ofrs: [],
    type_offer: undefined,
    type_offers: [],
    all_type_offers: [],
    cabs: [],

    affNetworks: [],

    isVisibleValidationErrorDialog: false,
    campaignValidationErrorMsg: '',

    copyDialogConfirm: false,
    formTab: null,

    massActionsDialog: false,
    confirmMassActions: false,

    newForm: true,
    formIsEdit: false,

    formCreator: 'unknown',
    formCreatorOrigin: 'unknown',
    formCreatorNeedUpdate: false,
    formCreatorLoading: false,

    formCreatorPercent: 0,
    formCreatorPercentOrigin: 0,
    formCreatorPercentNeedUpdate: false,
    formCreatorPercentLoading: false,

    formWhoAdd: 'unknown',
    formWhoAddOrigin: 'unknown',
    formWhoAddNeedUpdate: false,
    formWhoAddLoading: false,

    formWhoAddPercent: 0,
    formWhoAddPercentOrigin: 0,
    formWhoAddPercentNeedUpdate: false,
    formWhoAddPercentLoading: false,

    formCab: null,
    formRegion: null,
    formAccess: 0,

    // createNewTypeOffer: false,
    formTypeOffer: null,
    // formNewTypeOffer: null,

    // formIsMainOffer: false,
    // formMainOffer: null,

    formIsNewMainOffer: false,

    formNewMainOfferName: null,

    // formParentOffer: null,

    formURL: null,
    formCampaignId: null,
    // formCampaignId: 969152,
    formCampaignIdIsValid: false,

    // formURL: 'https://best.peekclickshndrk.com/15GaAK',
    // formCampaignId: '969152',

    formLoadingCampaignInfo: false,
    formIsReadyCampaignInfo: false,
    formIsLoadedCampaignInfo: false,
    formCampaignInfo: {},
    formTeasersCount: 0,

    formCampaignStatusName: '',
    formCampaignStatusDescription: '',

    formFullName: null,
    formFullNameNeedUpdate: false,
    formFullNameLoading: null,

    formNameOrigin: null,
    formName: null,
    formNameNeedUpdate: false,
    formNameLoading: false,

    formComments: null,
    formPayment: null,

    formStartCPC: 0.1,
    formMaxCPC: 0.1,
    formMaxCoef: 0,

    formStartApprove: 0,
    formEnableStartApprove: false,

    formActionOnly: false,

    formOptiOff: false,
    formAutopriceOff: false,

    formOptiOffLoading: false,
    formAutopriceOffLoading: false,

    formStasOptimized: false,

    formIsReadyModeration: false,

    checkingCampaign: false,
    campaignIsValid: false,

  }),

  // beforeRouteUpdate (to, from, next) {
  //   // console.log('beforeRouteUpdate from', from)
  //   // console.log('beforeRouteUpdate to', to)
  //   next()
  // },

  computed: {
    isAdmin () {return this.$store.getters['user/isAdmin']},
    allowEditCreatorAndWhoAdd () {return this.$store.getters['user/isAllowOptimizeCampaign']},
    // itemsPerPage () {return this.$store.getters['iface/getItemsPerPage']},
    cab () {return this.$store.getters['iface/cabValue']},
    // parentOffer () {return this.$store.getters['iface/parentOfferValue']},
    // stasOfr () {return this.$store.getters['iface/stasOfrValue']},

    autoPriceIsOff () {return this.$store.getters['iface/filterAutoPriceIsOff']},
    otiIsOff () {return this.$store.getters['iface/filterOptimizationIsOff']},

    locale () {return this.$store.getters['user/getLocale']},

    itemsPerPage: {
      get () {
        return this.$store.getters['iface/getItemsPerPage']
      },
      set (value) {
        this.$store.commit(IFACE_ITEMS_PER_PAGE, value)
      }
    },

    onModeration: {
      get () {
        return this.$store.getters['iface/isOptimizationOnModeration']
      },
      set (value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_ON_MODERATION_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_ON_MODERATION_OFF)
      }
    },

    isVisibleOptimizationAddItemWindowShow: {
      get () {return this.$store.getters['iface/isVisibleOptimizationAddItemWindowShow']},
      set (value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_ADD_ITEM_WINDOW_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_ADD_ITEM_WINDOW_OFF)
      }
    },

    refreshOptimizationTable: {
      get () {
        return this.$store.getters['iface/getOptimizationRefreshStatus']
      },
      set (value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_REFRESH_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_REFRESH_OFF)
      }
    },

    loading: {
      get () {
        return this.$store.getters['iface/getOptimizationRefreshIsLoading']
      },
      set (value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_LOADING_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_LOADING_OFF)
      }
    },

    optimizationTableMassActionEnableStatus: {
      get () {
        return this.$store.getters['iface/getOptimizationTableMassActionEnableStatus']
      }
    },

    optimizationTableMassActionAllow: {
      get () {
        return this.$store.getters['iface/getOptimizationTableMassActionAllowStatus']
      },
      set (value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF)
      }
    },

    optimizationTableMassActionStatus: {
      get () {
        return this.$store.getters['iface/getOptimizationTableMassActionStatus']
      },
      set (value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_OFF)
      }
    },

    isEnabledMassActions: {
      get () {
        return this.$store.getters['iface/getOptimizationTableMassActionStatus']
      },
      set (value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_LOADING_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_LOADING_OFF)
      }
    },

    loadingActions: {
      get () {
        return this.$store.getters['iface/getOptimizationTableMassActionIsLoading']
      },
      set (value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_LOADING_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_LOADING_OFF)
      }
    },

    dep: {
      get () {
        return this.$store.getters['iface/getDspDep']
      },
      set (value) {
        this.$store.commit(IFACE_DST_SET_DEP, value)
      }
    },

    depsAndTeams: {
      get () {
        return this.$store.getters['iface/getDspDeps']
      },
      set (value) {
        this.$store.commit(IFACE_DST_SET_DEPS, value)
      }
    },

    // formWhoAdd: {
    //   get () {
    //     return this.formWhoAddValue
    //   },
    //   set (value) {
    //     console.log('formWhoAdd', value)
    //     this.formWhoAddValue = value
    //   }

    // }

    optimizationAddNewTask: {
      get () {
        return this.$store.getters['iface/isVisibleOptimizationAddNewTaskWindow']
      },
      set (value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_ADD_NEW_TASK_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_ADD_NEW_TASK_OFF)
      }
    },
  },

  async created () {
    document.title = 'Optimization'
    this.formAccess = this.$store.getters['user/access']

    // this.setLocale(this.locale)

    try {
      const c = await api.getOptimizationCatalog()
      // window.console.log('getCatalog', c.data)
      Vue.set(this, 'allRegions', c.data.regions)
      Vue.set(this, 'allRegionsClear', c.data.regions.filter((reg) => !(reg === 'Tier 1' || reg === 'Tier 2')))
      // window.console.log('allRegionsClear', c.data.regions.filter((reg) => !(reg === 'Tier 1' || reg === 'Tier 2') ))
      // window.console.log('allRegionsClear', this.allRegionsClear)
      Vue.set(this, 'allCreatorsInfo', c.data.allCreatorsInfo)
      // Vue.set(this, 'parent_offers', c.data.parent_offers)
      // Vue.set(this, 'stas_ofrs', c.data.stas_ofrs)
      Vue.set(this, 'cabs', c.data.cabs)
      Vue.set(this, 'all_type_offers', c.data.type_offers)
      const user = this.$store.getters['user/getUserName']
      Vue.set(this, 'allCreators', [this.userNameAsI()].concat(Object.keys(c.data.allCreatorsInfo).filter(u => u !== user)))
      Vue.set(this, 'allWhoAddeds', [this.userNameAsI()].concat(c.data.who_addeds.filter(u => u !== user)))
      Vue.set(this, 'affNetworks', c.data.affNetworks)
      // Vue.set(this.options, 'itemsPerPage', this.$store.getters['iface/getItemsPerPage'])
      // console.log('created', this.$route)

      if (this.$route.params.campaignId) {
        // console.log('created campaignId', this.$route.params.campaignId)
        this.campaignId = this.$route.params.campaignId
      } else {
        this.getDataFromApi()
      }
    } catch (e) {
      window.console.error('getCatalog', e)
    }

  },

  mounted () {
    this.setHeaders(this.$store.getters['user/isAdmin'])
    // this.$store.commit(IFACE_DRAWER_LEFT_OFF)
  },

  beforeDestroy () {
    // this.$store.commit(IFACE_DRAWER_LEFT_ON)
  },

  methods: {
    setURL (value) {
      this.formURL = value.trim()
    },

    userNameAsI () {
      const user = this.$store.getters['user/getUserName']
      return 'I ( ' + user + ' )'
    },

    // clicked (value) {
    //   const index = this.expanded.indexOf(value)
    //   if (index === -1) {
    //     this.expanded.push(value)
    //   } else {
    //     this.expanded.splice(index, 1)
    //   }
    // },

    readonlyFormParam () {
      if ((this.isAdmin || this.isAllowOptimizeCampaign) && this.onModeration) {
        return false
      }

      if (this.onModeration) {
        if (this.formIsReadyModeration)
          return true
        else
          return false
      }

      if (this.onModeration)
        return false

      if (this.newForm)
        return false

      return true
    },

    isReadonlyCreatorAdnWhoAdd () {

      if ((this.isAdmin || this.isAllowOptimizeCampaign) && this.onModeration) {
        return false
      }

      if (this.onModeration) {
        if (this.formIsReadyModeration)
          return true
        else
          return false
      }

      if (this.onModeration)
        return false

      if (this.newForm)
        return false

      return !this.$store.getters['user/getIsAllowEditCreatorAndWhoAdd']
    },

    readonlyFormName () {
      if ((this.isAdmin || this.isAllowOptimizeCampaign) && this.onModeration) {
        return false
      }

      if (this.onModeration) {
        if (this.formIsReadyModeration)
          return true
        else
          return false
      }

      if (this.onModeration)
        return false

      if (this.newForm)
        return false

      return false
    },

    readonlyParamCampaignId () {
      if (this.newForm && this.onModeration)
        return false

      if (this.onModeration)
        return true

      if (this.newForm)
        return false

      return false
    },

    clearableForm () {
      if (this.onModeration)
        return true

      if (this.newForm || this.formIsEdit)
        return true

      return false
    },

    clickOnCampaignId (campaign) {
      console.log('clickOnCampaignId', this.$route.name)
      if (this.$route.name !== 'Edit')
        this.$router.push({
          name: 'Edit',
          params: {campaignId: campaign.campaign_id, campaign},
        })
      else {
        this.prepareCampaignAndShowDialog(campaign)
      }
    },

    prepareCampaignAndShowDialog (campaign) {

      if (campaign === undefined) {
        const campaignIdAsInt = parseInt(this.campaignId, 10)
        campaign = this.campaigns.find(c => c.campaign_id === campaignIdAsInt)
      }

      if (typeof campaign !== 'object') {
        window.console.log('notfound', this.campaigns, this.campaignId)
        return
      }

      // window.console.log('prepareCampaignAndShowDialog', campaign)
      document.title = 'Edit campaign Id: ' + campaign.campaign_id

      this.newForm = false

      this.formTab = 'tab-1'

      if (this.onModeration) {
        this.formIsEdit = true
        this.formIsReadyModeration = campaign.isReadyModeration === true
        // if (!this.formIsReadyModeration)
        //   this.isPersistentEditor = true
      }

      if (campaign.who_added === this.$store.getters['user/getUserName']) {
        this.formWhoAdd = this.userNameAsI()
      } else {
        this.formWhoAdd = campaign.who_added
      }

      this.formWhoAddOrigin = this.formWhoAdd

      this.formWhoAddPercent = campaign.who_added_proc
      this.formWhoAddPercentOrigin = this.formWhoAddPercent

      if (campaign.creator === this.$store.getters['user/getUserName']) {
        this.formCreator = this.userNameAsI()
      } else {
        this.formCreator = campaign.creator
      }

      this.formCreatorOrigin = this.formCreator

      this.formCreatorPercent = campaign.creator_proc
      this.formCreatorPercentOrigin = this.formCreatorPercent

      this.formCab = this.cabs.find(cab => cab.id === campaign.client_id.toString())
      this.formRegion = campaign.region
      this.formAccess = campaign.access
      this.formTypeOffer = campaign.type_offer

      // if (campaign.stas_ofr !== null && typeof campaign.stas_ofr === 'string' && campaign.stas_ofr.length > 0) {
      //   this.formIsMainOffer = true
      //   this.formMainOffer = campaign.stas_ofr
      // } else {
      //   this.formIsMainOffer = false
      //   this.formMainOffer = null
      // }

      // if (campaign.parent_offer !== null && typeof campaign.parent_offer === 'string' && campaign.parent_offer.length > 0) {
      //   this.formParentOffer = campaign.parent_offer
      // } else {
      //   this.formParentOffer = null
      // }

      this.formURL = campaign.link
      this.formCampaignId = campaign.campaign_id

      this.formFullName = campaign.full_name
      this.formName = campaign.name
      this.formNameOrigin = campaign.name
      this.formComments = campaign.comments

      this.formPayment = campaign.payment / 100
      this.formStartCPC = campaign.start_cpc / 10
      this.formMaxCPC = campaign.max_cpc === null ? 0 : campaign.max_cpc / 10
      this.formMaxCoef = campaign.max_coef === null ? 0 : campaign.max_coef
      this.formActionOnly = campaign.action_only ? true : false

      this.formEnableStartApprove = campaign.start_approve > 0
      this.formStartApprove = campaign.start_approve

      this.formOptiOff = campaign.opti_off ? true : false
      this.formAutopriceOff = campaign.autoprice_off ? true : false

      // this.isVisibleOptimizationAddItemWindowShow = true
      this.formStasOptimized = campaign.stas_optimized ? true : false
      this.campaignEditorDialog = true
      this.getCampaignInfoFromCab()

    },

    resetForm () {
      this.formCreator = null
      this.formCreatorOrigin = null

      this.formCreatorPercent = 0
      this.formCreatorPercentOrigin = 0

      this.formWhoAdd = null
      this.formWhoAddOrigin = null

      this.formWhoAddPercent = 0
      this.formWhoAddPercentOrigin = 0

      this.formCab = null
      this.formRegion = null
      this.formAccess = this.$store.getters['user/access']
      this.formTypeOffer = null

      // this.formIsMainOffer = false
      // this.formMainOffer = null
      // this.formParentOffer = null

      this.formURL = null
      this.formCampaignId = null

      this.formFullName = null
      this.formName = null
      this.formComments = null

      this.formPayment = 0
      this.formStartCPC = 0.1
      this.formMaxCPC = 0.1

      this.formActionOnly = false

      this.formStasOptimized = false

      this.formFullNameNeedUpdate = false
      this.formNameOrigin = null

      this.formStartApprove = 0
      this.formEnableStartApprove = false
    },

    async getDataFromApi () {
      this.loading = true
      return new Promise((resolve, reject) => {
        // const {sortBy, sortDesc, page, itemsPerPage} = this.options
        // const {page, itemsPerPage} = this.options

        // window.console.log('itemsPerPage', sortBy, sortDesc, itemsPerPage, page - 1)
        // window.console.log('FILTER user', this.user, this.campaignId)

        const filter = {}

        // if (this.$route.params.campaignId) {
        //   console.log('created campaignId firstRun', this.$route.params.campaignId)
        //
        // }

        if (this.firstRun && this.$route.params.campaignId) {
          // console.log('firstRun', this.firstRun)
          // this.firstRun = false
        } else {
          if (this.creator === 'My') {
            filter.creator = this.$store.getters['user/getUserName']
          } else if (this.creator !== 'All') {
            filter.creator = this.creator
          }

          if (this.whoAdded === 'My') {
            filter.who_added = this.$store.getters['user/getUserName']
          } else if (this.whoAdded !== 'All') {
            filter.who_added = this.whoAdded
          }
        }

        if (this.name !== null) {
          filter.name = this.name
        }

        if (this.campaignId !== null) {
          filter.campaignId = this.campaignId
        }

        if (this.region !== undefined) {
          filter.region = this.region
        }

        if (this.type_offer !== undefined) {
          filter.type_offer = this.type_offer
        }

        if (this.type_offer !== undefined) {
          filter.type_offer = this.type_offer
        }

        if (this.cab !== undefined) {
          filter.client_id = this.cab.id
        }

        // if (this.parentOffer !== undefined) {
        //   filter.parent_offer = this.parentOffer
        // }

        // if (this.stasOfr !== undefined) {
        //   filter.stas_ofr = this.stasOfr
        // }

        if (this.autoPriceIsOff) {
          filter.autoprice_off = true
        }

        if (this.otiIsOff) {
          filter.opti_off = true
        }

        const resFilter = Object.keys(filter).length > 0 ? filter : undefined

        // window.console.log('resFilter', resFilter, Object.keys(filter), filter)

        // api.getOptimizations(itemsPerPage, page - 1).then(c => {
        api.getOptimizationCampaigns(this.itemsPerPage, this.page - 1, resFilter, this.onModeration).then(c => {
          // window.console.log('getOptimizationsQuery', c.data)
          this.loading = false

          this.total = c.data.total
          const user = this.$store.getters['user/getUserName']

          c.data.campaigns.reduce((acc, item) => {
            const cab = this.cabs.find((e) => e.id === item.client_id.toString())
            // window.console.log('this.cabs', item, cab)
            item.cab_name = cab.name
            item.isReadyModeration = item.isReadyModeration === 1
          }, c.data.campaigns)

          Vue.set(this, 'campaigns', c.data.campaigns)
          Vue.set(this, 'creators', ['All', 'My'].concat(c.data.creators.filter(u => u !== user)))
          Vue.set(this, 'whoAddeds', ['All', 'My'].concat(c.data.who_addeds.filter(u => u !== user)))
          Vue.set(this, 'regions', c.data.regions)

          // if (this.locale === 'EN') {
          //   Vue.set(this, 'type_offers', c.data.type_offers.map((name) => {
          //     const index = this.all_type_offers_RU.indexOf(name)
          //     if (index === -1)
          //       return {text: name, value: name}
          //     else
          //       return this.all_type_offers_EN[index]
          //   }))
          // } else {
          //   Vue.set(this, 'type_offers', c.data.type_offers)
          // }

          Vue.set(this, 'type_offers', c.data.type_offers)

          // Vue.set(this, 'parent_offers', c.data.parent_offers)
          // Vue.set(this, 'stas_ofrs', c.data.stas_ofrs)

          const cabs = c.data.cabs.reduce((acc, item) => {
            const cab = this.cabs.find((e) => e.id === item.toString())
            acc.push({id: item, name: cab.name + ' - ' + item})
            return acc
          }, [])

          // window.console.log('cabs', cabs)

          this.$store.commit(IFACE_CAB_ITEMS, cabs)
          // this.$store.commit(IFACE_STAS_OFR_ITEMS, c.data.stas_ofrs)
          // this.$store.commit(IFACE_PARENT_OFFER_ITEMS, c.data.parent_offers)

          // window.console.log('type_offers', this.type_offers)
          // window.console.log('creators', c.data.creators)
          // window.console.log('regions', c.data.regions)
          // window.console.log('campaigns', c.data.campaigns)
          // window.console.log('cabs', c.data.cabs)

          resolve()
        }).catch(e => {
          this.loading = false
          window.console.error('getOptimizations', e)
          reject(e)
        })
      }).then(() => {
        if (this.firstRun && this.$route.params.campaignId) {
          if (this.campaigns.length === 1) {
            this.clickOnCampaignId(this.campaigns[0])
          }
          this.firstRun = false
        }
      })
    },

    setHeaders () {
      const headers = [
        {text: 'Date', align: 'start', value: 'optimization_date', class: 'sticky-header'},
        {text: 'Who added', align: 'start', value: 'who_added', class: 'sticky-header'},
        // {text: '%', align: 'center', value: 'creator_proc', class: 'sticky-header'},
        {text: 'Creator', align: 'start', value: 'creator', class: 'sticky-header'},
        // {text: '%', align: 'center', value: 'who_added_proc', class: 'sticky-header'},
        {text: 'Id', align: 'center', value: 'campaign_id', class: 'sticky-header'},
        {text: 'Name', align: 'center', value: 'name', class: 'sticky-header'},
        {text: 'Region', align: 'center', value: 'region', class: 'sticky-header'},
        {text: 'Type', align: 'center', value: 'type_offer', class: 'sticky-header'},
        {text: 'Payment, $', align: 'center', value: 'payment', class: 'sticky-header'},
        {text: 'Start CPC, ¢', align: 'center', value: 'start_cpc', class: 'sticky-header'},
        {text: 'Max CPC, ¢', align: 'center', value: 'max_cpc', class: 'sticky-header'},
        {text: 'New teaser’s CPC, ¢', align: 'center', value: 'manual_cpc', class: 'sticky-header'},
        {text: 'Max Coef', align: 'center', value: 'max_coef', class: 'sticky-header'},
        {text: 'Start approve, %', align: 'center', value: 'start_approve', class: 'sticky-header'},
        {text: 'Manual margin, %', align: 'center', value: 'manual_margin', class: 'sticky-header'},
        {text: 'Cab', align: 'center', value: 'cab_name', class: 'sticky-header'}
      ]
      Vue.set(this, 'headers', headers)
    },

    timeConverter (UNIX_timestamp) {
      const a = new Date(UNIX_timestamp * 1000)
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const year = a.getFullYear()
      const month = months[a.getMonth()]
      const date = a.getDate()
      // const hour = a.getHours();
      // const min = a.getMinutes();
      // const sec = a.getSeconds();
      return date + ' ' + month + ' ' + year
    },

    isAllowCheckCampaign () {
      if (typeof this.formName === 'string' && this.formName.length > 0) {
        if (typeof this.formURL === 'string' && this.formURL.length > 0) {
          if (typeof this.formCampaignId === 'number' ||
              typeof this.formCampaignId === 'string' && this.formCampaignId.length > 0 && /\d+/.test(this.formCampaignId)) {
            if (this.formCab) {
              if (
                  ((typeof this.formStartCPC === 'string' && parseFloat(this.formStartCPC) >= 0.1) ||
                      (typeof this.formStartCPC === 'number' && this.formStartCPC >= 0.1))
                  &&
                  ((typeof this.formMaxCPC === 'string' && parseFloat(this.formMaxCPC) > this.formStartCPC) ||
                      (typeof this.formMaxCPC === 'number' && this.formMaxCPC > this.formStartCPC))
                  && ((typeof this.formMaxCoef === 'string' && parseFloat(this.formMaxCoef) > -1) ||
                      (typeof this.formMaxCoef === 'number' && this.formMaxCoef > -1))
              ) {
                if (typeof this.formTypeOffer === 'string' && this.formTypeOffer.length > 0) {
                  if (typeof this.formRegion === 'string' && this.formRegion.length > 0) {
                    if (
                        (typeof this.formPayment === 'string' && parseFloat(this.formPayment) > 0) ||
                        (typeof this.formPayment === 'number' && this.formPayment > 0)
                    ) {
                      return true
                    }
                  }
                }
              }
            }
          }
        }
      }
      return false
    },

    async checkCampaign () {
      this.checkingCampaign = true
      this.campaignIsValid = false
      const req = {
        client_id: this.formCab.id,
        campaign_id: this.formCampaignId,
        url: this.formURL.trim(),
        start_cpc: this.formStartCPC
      }

      try {
        const res = await api.checkCampaign(req)
        // window.console.info('checkCampaign res', res)

        if (res.status === 200) {
          if (!res.data.error) {
            // window.console.info('checkCampaign OKKK', res)
            this.campaignIsValid = true
            this.campaignValidationErrorMsg = ''
            this.formFullName = res.data.name
          } else {
            this.campaignIsValid = false
            this.campaignValidationErrorMsg = res.data.message
            this.isVisibleValidationErrorDialog = true
          }
        } else {
          this.campaignIsValid = false
          this.campaignValidationErrorMsg = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        window.console.error('checkCampaign', e)
        this.campaignIsValid = false
        this.campaignValidationErrorMsg = JSON.stringify(e, null, 2)
        this.isVisibleValidationErrorDialog = true
      }
      this.checkingCampaign = false
    },

    isAllowModerate () {
      if (this.campaignIsValid && this.formRegion && this.formTypeOffer && this.formName)
        if (
            (typeof this.formPayment === 'string' && parseFloat(this.formPayment) > 0) ||
            (typeof this.formPayment === 'number' && this.formPayment > 0)) {
          if (
              (typeof this.formMaxCPC === 'string' && parseFloat(this.formMaxCPC) >= 0.1) ||
              (typeof this.formMaxCPC === 'number' && this.formMaxCPC >= 0.1)) {
            return true
          }
        }

      return false
    },

    getCampaignObject () {
      let who_added

      if (/^I \( /.test(this.formWhoAdd)) {
        who_added = this.$store.getters['user/getUserName']
      } else {
        who_added = this.formWhoAdd
      }

      let creator

      if (/^I \( /.test(this.formCreator)) {
        creator = this.$store.getters['user/getUserName']
      } else {
        creator = this.formCreator
      }

      let payment

      if (typeof this.formPayment === 'string') {
        payment = parseFloat(this.formPayment) * 100
      } else {
        payment = this.formPayment * 100
      }

      let max_cpc

      if (typeof this.formMaxCPC === 'string') {
        max_cpc = Math.round(parseFloat(this.formMaxCPC) * 10)
      } else {
        max_cpc = Math.round((this.formMaxCPC * 10).toFixed(2))
      }

      let start_cpc

      if (typeof this.formStartCPC === 'string') {
        start_cpc = parseFloat(this.formStartCPC) * 10
      } else {
        start_cpc = Math.round((this.formStartCPC * 10).toFixed(2))
      }

      let max_coef

      if (typeof this.formMaxCoef === 'string') {
        max_coef = parseFloat(this.formMaxCoef)
      } else {
        max_coef = this.formMaxCoef
      }

      const campaign = {
        who_added,
        who_added_proc: this.formWhoAddPercent,
        creator,
        creator_proc: this.formCreatorPercent,
        client_id: this.formCab.id,
        network: this.formCab.network,

        link: this.formURL.trim(),
        campaign_id: this.formCampaignId,
        full_name: this.formFullName,

        payment,
        start_cpc,
        max_cpc,
        max_coef,
        action_only: this.formActionOnly
      }

      if (this.formEnableStartApprove) {
        campaign.start_approve = this.formStartApprove
      } else {
        campaign.start_approve = 0
      }

      // if (this.createNewTypeOffer) {
      //   if (this.formNewTypeOffer)
      //     campaign.type_offer = this.formNewTypeOffer
      // } else {
      if (this.formTypeOffer)
        campaign.type_offer = this.formTypeOffer
      // }

      // campaign.access = this.allCreatorsInfo[creator].access
      if (this.isAdmin || this.isAllowOptimizeCampaign) {
        // campaign.access = this.formAccess
        campaign.access = this.allCreatorsInfo[creator].access
      } else {
        campaign.access = this.$store.getters['user/access']
      }

      if (this.formRegion) {
        campaign.region = this.formRegion
      }

      if (this.formName) {
        campaign.name = this.formName
      }

      if (this.formComments) {
        campaign.comments = this.formComments
      } else {
        campaign.comments = ''
      }

      if (this.formFullName) {
        campaign.full_name = this.formFullName
      }

      // if (this.formIsMainOffer) {
      //   if (this.formIsNewMainOffer) {
      //     if (this.formNewMainOfferName)
      //       campaign.stas_ofr = this.formNewMainOfferName
      //   } else {
      //     if (this.formMainOffer)
      //       campaign.stas_ofr = this.formMainOffer
      //   }
      // }

      // if (this.formParentOffer) {
      //   campaign.parent_offer = this.formParentOffer
      // }

      return campaign
    },

    async addNewCampaign () {

      const campaign = this.getCampaignObject()

      // window.console.log('saveNewCampaign', campaign)

      try {
        const res = await api.saveNewCampaign(campaign)
        window.console.log('saveNewCampaign res', res)
        if (!res.data.error) {
          this.formIsEdit = true
          this.campaignIsValid = false
          this.newForm = false
          this.$store.commit(IFACE_DRAWER_RIGHT_ON)
          this.onModeration = true
          this.campaignEditorDialog = false
        }
      } catch (e) {
        window.console.log('saveNewCampaign error', e)
      }

    },

    async cancelModeration () {
      try {
        const res = await api.cancelModerationCampaign({campaign_id: this.formCampaignId})
        // window.console.log('cancelModerationCampaign res', res)
        if (!res.data.error) {
          this.campaignEditorDialog = false
          this.getDataFromApi()
          // this.formIsEdit = true
          // this.campaignIsValid = false
          // this.newForm = false
          // this.$store.commit(IFACE_DRAWER_RIGHT_ON)
          // this.onModeration = true
        }
      } catch (e) {
        window.console.log('cancelModeration error', e)
      }
    },

    async moderationCampaign () {
      const campaign = this.getCampaignObject()

      // window.console.log('moderationCampaign', campaign)

      try {
        const res = await api.moderationCampaign(campaign)
        window.console.log('moderationCampaign res', res)
        if (!res.data.error) {
          this.campaignEditorDialog = false
          this.getDataFromApi()
          // this.formIsEdit = true
          // this.campaignIsValid = false
          // this.newForm = false
          // this.$store.commit(IFACE_DRAWER_RIGHT_ON)
          // this.onModeration = true
        }
      } catch (e) {
        window.console.log('moderationCampaign error', e)
        this.campaignEditorDialog = false
      }
    },

    async onOptimizeCampaign () {
      // const campaign = this.getCampaignObject()

      // window.console.log('moderationCampaign', campaign)

      try {
        const res = await api.optimizeCampaign({campaign_id: [this.formCampaignId]})
        // window.console.log('moderationCampaign res', res)
        if (!res.data.error) {
          this.campaignEditorDialog = false
          this.getDataFromApi()
          // this.formIsEdit = true
          // this.campaignIsValid = false
          // this.newForm = false
          // this.$store.commit(IFACE_DRAWER_RIGHT_ON)
          // this.onModeration = true
        }
      } catch (e) {
        window.console.log('moderationCampaign error', e)
        this.campaignEditorDialog = false
      }
    },

    async massCancelModeration () {
      try {
        const res = await api.cancelModerationCampaign({campaign_id: this.formCampaignId})
        // window.console.log('cancelModerationCampaign res', res)
        if (!res.data.error) {
          this.campaignEditorDialog = false
          this.getDataFromApi()
          // this.formIsEdit = true
          // this.campaignIsValid = false
          // this.newForm = false
          // this.$store.commit(IFACE_DRAWER_RIGHT_ON)
          // this.onModeration = true
        }
      } catch (e) {
        window.console.log('cancelModeration error', e)
      }
    },

    async massModerationCampaign () {
      const campaign = this.getCampaignObject()

      // window.console.log('moderationCampaign', campaign)

      try {
        const res = await api.moderationCampaign(campaign)
        window.console.log('moderationCampaign res', res)
        if (!res.data.error) {
          this.campaignEditorDialog = false
          this.getDataFromApi()
          // this.formIsEdit = true
          // this.campaignIsValid = false
          // this.newForm = false
          // this.$store.commit(IFACE_DRAWER_RIGHT_ON)
          // this.onModeration = true
        }
      } catch (e) {
        window.console.log('moderationCampaign error', e)
        this.campaignEditorDialog = false
      }
    },

    async massOptimizeCampaign () {
      // const campaign = this.getCampaignObject()

      // window.console.log('moderationCampaign', campaign)

      try {
        const res = await api.optimizeCampaign({campaign_id: this.selected.map(t => t.campaign_id)})
        window.console.log('moderationCampaign res', res)
        if (!res.data.error) {
          this.campaignEditorDialog = false
          this.getDataFromApi()
          // this.formIsEdit = true
          // this.campaignIsValid = false
          // this.newForm = false
          // this.$store.commit(IFACE_DRAWER_RIGHT_ON)
          // this.onModeration = true
        }
      } catch (e) {
        window.console.log('moderationCampaign error', e)
        this.campaignEditorDialog = false
      }
    },

    validateCampaignId (campaignId) {
      if (campaignId) {

        if (/\d+/.test(campaignId)) {
          this.formCampaignIdIsValid = true
          return true
        } else {
          this.formCampaignIdIsValid = false
          return 'Allow only digits'
        }
      } else {
        return 'Must be digits'
      }
    },

    readyFetchCampaignInfo () {
      return this.formCab && this.formCampaignIdIsValid
    },

    getCampaignInfoFromCab () {
      this.formLoadingCampaignInfo = true
      this.formIsReadyCampaignInfo = false
      const req = {campaign_id: this.formCampaignId, client_id: this.formCab.id}
      api.getCampaignInfoFromCab(req).then((res) => {
        // window.console.info('getCampaignInfoFromCab res', res)
        if (res.data.error) {
          this.formLoadingCampaignInfo = false
          this.campaignValidationErrorMsg = res.data.message
          this.isVisibleValidationErrorDialog = true
          return
        }

        if (this.formFullName !== res.data.info.name) {
          this.formFullName = res.data.info.name
          this.formFullNameNeedUpdate = true
        }

        const match = res.data.info.name.match(/_([A-Z][A-Z])/)

        if (Array.isArray(match)) {
          if (match.length === 2) {
            this.formRegion = match[1]
          }
        }

        const matchUsers = res.data.info.name.match(/^(\w+):(\w+) /i)

        if (Array.isArray(matchUsers)) {
          // console.log('matchUsers', matchUsers)

          if (matchUsers.length === 3) {
            if (matchUsers[1] === this.$store.getters['user/getUserName']) {
              this.formWhoAdd = this.userNameAsI()
            } else {
              this.formWhoAdd = matchUsers[1]
            }

            if (matchUsers[2] === this.$store.getters['user/getUserName']) {
              this.formCreator = this.userNameAsI()
            } else {
              this.formCreator = matchUsers[2]
            }

          }
        }

        this.formTeasersCount = res.data.teasersCount

        this.formCampaignStatusName = res.data.info.status.name
        this.formCampaignStatusDescription = res.data.info.status.reason

        this.formLoadingCampaignInfo = false
        this.formIsReadyCampaignInfo = true
        Vue.set(this, 'formCampaignInfo', res.data.info)
      }).catch((e) => {
        this.formLoadingCampaignInfo = false
        this.campaignValidationErrorMsg = e
        this.isVisibleValidationErrorDialog = true

        window.console.error('getCampaignInfoFromCab', e)
      })
    },

    resetFormCampaignId () {
      this.formIsReadyCampaignInfo = false
      Vue.set(this, 'formCampaignInfo', {})
    },

    getCampaignLimits () {
      if (this.formIsReadyCampaignInfo)
        if (this.formCampaignInfo.limitsFilter.limitType === 'budget_limits' &&
            typeof this.formCampaignInfo.limitsFilter.dailyLimit == 'number') {
          return this.formCampaignInfo.limitsFilter.dailyLimit / 100
        }
      return 'E'
    },

    onChangeFormOptiOff (value) {
      this.formOptiOffLoading = true

      const req = {
        campaign_id: this.formCampaignId,
        type: 'number',
        name: 'opti_off',
        value: value ? 1 : 0
      }

      api.updateValue(req).then(
          (res) => {
            this.formOptiOffLoading = false
            if (res.error) {
              window.console.log('saveValue error', res)
            }
            this.getDataFromApi()
            this.fetchHistoryForOptiOffAndAutopriceOff()
          }).catch((e) => {
        window.console.error('formOptiOff', e, req)
      })
    },

    onChangeFormAutopriceOff (value) {
      this.formAutopriceOffLoading = true

      const req = {
        campaign_id: this.formCampaignId,
        type: 'number',
        name: 'autoprice_off',
        value: value ? 1 : 0
      }

      api.updateValue(req).then(
          (res) => {
            this.formAutopriceOffLoading = false
            if (res.error) {
              window.console.log('saveValue error', res)
            }
            this.getDataFromApi()
            this.fetchHistoryForOptiOffAndAutopriceOff()
          }).catch((e) => {
        window.console.error('formAutopriceOff', e, req)
      })

    },

    onUpdateFullName () {
      this.formFullNameLoading = true
      const req = {
        campaign_id: this.formCampaignId,
        type: 'string',
        name: 'full_name',
        value: this.formFullName
      }

      api.updateValue(req).then(
          (res) => {
            if (res.data.error) {
              window.console.log('saveValue error', res)
              this.formFullNameLoading = false
              return
            }
            this.formFullNameLoading = false
            this.formFullNameNeedUpdate = false
            this.getDataFromApi()
          }).catch((e) => {
        this.formFullNameLoading = false
        window.console.error('updateValue', e, req)
      })
    },

    onUpdateName () {

      this.formNameLoading = true

      const req = {
        campaign_id: this.formCampaignId,
        type: 'string',
        name: 'name',
        value: this.formName
      }

      api.updateValue(req).then(
          (res) => {
            if (res.data.error) {
              window.console.log('saveValue error', res)
              this.formNameLoading = false
              return
            }
            this.formNameLoading = false
            this.formNameNeedUpdate = false
            this.getDataFromApi()
          }).catch((e) => {
        this.formNameLoading = false
        window.console.error('updateValue', e, req)
      })
    },

    onUpdateCreator () {

      this.formCreatorLoading = true

      let creator

      if (/^I \( /.test(this.formCreator)) {
        creator = this.$store.getters['user/getUserName']
      } else {
        creator = this.formCreator
      }

      const req = {
        campaign_id: this.formCampaignId,
        type: 'string',
        name: 'creator',
        value: creator
      }

      api.updateValue(req).then(
          (res) => {
            if (res.data.error) {
              window.console.log('saveValue error', res)
              this.formCreatorLoading = false
              return
            }
            this.formCreatorLoading = false
            this.formCreatorNeedUpdate = false
            this.getDataFromApi()

            if (creator === this.$store.getters['user/getUserName']) {
              this.formCreatorOrigin = this.userNameAsI()
            } else {
              this.formCreatorOrigin = creator
            }
          }).catch((e) => {
        this.formCreatorLoading = false
        // this.formNameNeedUpdate = false
        window.console.error('updateValue', e, req)
      })
    },

    onUpdateCreatorPercent () {

      this.formCreatorPercentLoading = true

      const req = {
        campaign_id: this.formCampaignId,
        type: 'number',
        name: 'creator_proc',
        value: this.formCreatorPercent
      }

      api.updateValue(req).then(
          (res) => {
            if (res.data.error) {
              window.console.log('saveValue error', res)
              this.formCreatorPercentLoading = false
              return
            }
            this.formCreatorPercentLoading = false
            this.formCreatorPercentNeedUpdate = false
            this.formCreatorPercentOrigin = this.formCreatorPercent
            this.getDataFromApi()
          }).catch((e) => {
        this.formCreatorPercentLoading = false
        // this.formNameNeedUpdate = false
        window.console.error('updateValue', e, req)
      })
    },

    onUpdateWhoAdd () {

      this.formWhoAddLoading = true

      let whoAdded

      if (/^I \( /.test(this.formWhoAdd)) {
        whoAdded = this.$store.getters['user/getUserName']
      } else {
        whoAdded = this.formWhoAdd
      }

      const req = {
        campaign_id: this.formCampaignId,
        type: 'string',
        name: 'who_added',
        value: whoAdded
      }

      api.updateValue(req).then(
          (res) => {
            if (res.data.error) {
              window.console.log('saveValue error', res)
              this.formWhoAddLoading = false
              return
            }
            this.formWhoAddLoading = false
            this.formWhoAddNeedUpdate = false
            this.getDataFromApi()

            if (whoAdded === this.$store.getters['user/getUserName']) {
              this.formWhoAddOrigin = this.userNameAsI()
            } else {
              this.formWhoAddOrigin = whoAdded
            }
          }).catch((e) => {
        this.formWhoAddLoading = false
        // this.formNameNeedUpdate = false
        window.console.error('updateValue', e, req)
      })
    },

    onUpdateWhoAddPercent () {

      this.formWhoAddPercentLoading = true

      const req = {
        campaign_id: this.formCampaignId,
        type: 'number',
        name: 'who_added_proc',
        value: this.formWhoAddPercent
      }

      api.updateValue(req).then(
          (res) => {
            if (res.data.error) {
              window.console.log('saveValue error', res)
              this.formWhoAddPercentLoading = false
              return
            }
            this.formWhoAddPercentLoading = false
            this.formWhoAddPercentNeedUpdate = false
            this.formWhoAddPercentOrigin = this.formWhoAddPercent
            this.getDataFromApi()
          }).catch((e) => {
        this.formWhoAddPercentLoading = false
        // this.formNameNeedUpdate = false
        window.console.error('updateValue', e, req)
      })
    },

    confirmCopyCampaign () {

      this.formTab = 'tab-1'
      // this.resetForm()
      this.isPersistentEditor = true
      this.newForm = true
      this.formIsEdit = false
      this.formFullName = null
      this.formIsReadyCampaignInfo = false
      this.$set(this, 'formCampaignInfo', {})
      // this.formWhoAddPercent = 10
      this.formWhoAdd = this.userNameAsI()
      this.formCreator = 'unknown'
      // this.formCreatorPercent = 20
      this.formAccess = this.$store.getters['user/access']
      // this.campaignEditorDialog = true

      this.formIsReadyModeration = false
      this.formCampaignId = null
      this.copyDialogConfirm = false
      // this.formIsEdit = true
    },

    validateMaxCPC (value) {
      // console.log('validateMaxCPC', value)
      if (typeof value === 'number') {
        if (value < this.formStartCPC || value == this.formStartCPC) {
          return 'Must be more Start CPC'
        } else {
          return true
        }
      } else if (typeof value === 'string') {
        const f = parseFloat(value)
        if (isNaN(f)) {
          return 'Must be number'
        } else if (f < this.formStartCPC || f == this.formStartCPC) {
          return 'Must be more Start CPC'
        } else {
          return true
        }
      }
      return 'Unknown error'
    },

    validateMaxCoef (value) {
      // console.log('validateMaxCPC', value)
      if (typeof value === 'number') {
        if (value < 0) {
          return 'Must be more 0'
        } else {
          return true
        }
      } else if (typeof value === 'string') {
        const f = parseFloat(value)
        if (isNaN(f)) {
          return 'Must be number'
        } else if (f < 0) {
          return 'Must be more 0'
        } else {
          return true
        }
      }
      return 'Unknown error'
    },

    validateStartApprove (value) {
      // console.log('validateStartApprove', value, typeof value)
      if (typeof value === 'number') {
        return true
      } else if (typeof value === 'string') {
        const f = parseFloat(value)
        if (isNaN(f)) {
          return 'Must be number'
        }
        return true
      }
      return 'Must be a number'
    },

    // setLocale (value) {
    //   // if (value === 'RU') {
    //   //   this.$set(this, 'all_type_offers', this.all_type_offers_RU)
    //   // } else if (value === 'EN') {
    //   //   this.$set(this, 'all_type_offers', this.all_type_offers_EN)
    //   //
    //   // }
    // },

    // getTypeOfferName (name) {
    //   if (this.locale === 'EN') {
    //     const index = this.all_type_offers_RU.indexOf(name)
    //     if (index === -1)
    //       return name
    //     else
    //       return this.all_type_offers_EN[index].text
    //   } else {
    //     return name
    //   }
    // },

    onExpand (value, value2) {
      // window.console.log('onExpand', value, value2)
      // value2.isExpanded = !value2.isExpanded
      value2.expand(!value2.isExpanded)
    },

    async fetchHistoryForOptiOffAndAutopriceOff () {
      this.dataIsReady = false

      const reqOpti_off = {
        campaign_id: this.campaignId,
        typeOfField: 'number',
        name: 'opti_off'
      }

      try {
        const res = await api.getHistoryOfFieldForCampaign(reqOpti_off)
        if (res.data.error) {
          window.console.log('getHistoryOfFieldForCampaign error', res)
          this.errorMsg = res.data.msg
          this.errorDialog = true
        }
        this.stockOptionsOptiOff.series[0].data = res.data.history

      } catch (e) {
        window.console.log('getHistoryOfFieldForCampaign error', e)
      }

      const reqAutoprice_off = {
        campaign_id: this.campaignId,
        typeOfField: 'number',
        name: 'autoprice_off'
      }

      try {
        const res = await api.getHistoryOfFieldForCampaign(reqAutoprice_off)
        if (res.data.error) {
          window.console.log('getHistoryOfFieldForCampaign error', res)
          this.errorMsg = res.data.msg
          this.errorDialog = true
        }
        this.stockOptionsAutopriceOff.series[0].data = res.data.history
      } catch (e) {
        window.console.log('getHistoryOfFieldForCampaign error', e)
      }

      this.dataIsReady = true
    },

    getPeerClickUrl (link) {
      if (link) {
        // console.log('getPeerclickUrl', link)
        const match = link.match(/^.*\/([0-9a-zA-Z]+)\??$/)

        if (match) {

          const dateStr = DateTime.fromObject({zone: 'America/Los_Angeles'})
          if (match.length === 2) {
            const filter = {
              'id_custom_filter': null,
              'sort': '[index]DESC',
              'affiliate_c': null,
              'like': '[flow]postfix:' + match[1],
              'grouped[]': 1,
              'date_compare_end': null,
              'selectedGroup': 0,
              'date_compare_begin': null,
              'isTab': false,
              'endHour': 23,
              'utc': '-07:00',
              'limit': 100,
              'source_c': null,
              'offset': 0,
              'group_c': null,
              'datebegin': dateStr.toISODate(),
              'subuser_c': null,
              'selectedUsers[]': [
                'my'
              ],
              'startHour': 0,
              'dateend': dateStr.toISODate()
            }
            const url = 'https://panel.peerclick.com/login/index.html#/stats?group=flow&tab=false&tab_filter=false&filter=' + encode(JSON.stringify(filter))
            return url
          }
        } else {
          return 'https://panel.peerclick.com/login/index.html#/stats'
        }

      } else {
        return 'https://panel.peerclick.com/login/index.html#/stats'
      }

    }
  },

  watch: {
    // options: {
    //   handler () {
    //     this.getDataFromApi()
    //   },
    //   deep: true,
    // },

    isAdmin (value) {
      this.setHeaders(value)
    },

    itemsPerPage (value) {
      Vue.set(this.options, 'itemsPerPage', parseInt(value, 10))
      if (value > 15) {
        this.tableHideDefaultFooter = false
      }
      this.getDataFromApi()
    },

    whoAdded () {
      this.getDataFromApi()
    },

    creator () {
      this.getDataFromApi()
    },

    name (value) {
      if ((typeof value === 'string' && value.length > 1) || value === null)
        this.getDataFromApi()
    },

    campaignId (value) {
      if (/\d+/.test(value) || value === null)
        this.getDataFromApi()
    },

    region (value) {
      if (this.regions.includes(value) || value === undefined)
        this.getDataFromApi()
    },

    type_offer () {
      this.getDataFromApi()
    },

    cab () {
      this.getDataFromApi()
    },

    // parentOffer () {
    //   this.getDataFromApi()
    // },
    //
    // stasOfr () {
    //   this.getDataFromApi()
    // },

    isVisibleOptimizationAddItemWindowShow () {

      // window.console.log('isVisibleOptimizationAddItemWindowShow', value, 'formIsEdit', this.formIsEdit, 'newForm', this.newForm)
      // if (!value)
      //   this.formIsEdit = false
      //
      // if (this.newForm && value) {
      //   this.formWhoAdd = this.userNameAsI()
      //   this.formIsEdit = true
      // } else {
      //   if (this.onModeration)
      //     this.formIsEdit = true
      //   else
      //     this.formIsEdit = false
      // }

      // this.newForm = true
      // this.formIsEdit = false
      this.formTab = 'tab-1'
      this.resetForm()
      this.isPersistentEditor = true
      this.newForm = true
      this.formIsEdit = false
      this.formWhoAdd = this.userNameAsI()
      this.formWhoAddPercent = 20
      this.formCreator = 'unknown'
      this.formCreatorPercent = 5
      this.formAccess = this.$store.getters['user/access']
      this.campaignEditorDialog = true
    },

    onModeration () {
      this.getDataFromApi()
    },

    autoPriceIsOff () {
      this.getDataFromApi()
    },

    otiIsOff () {
      this.getDataFromApi()
    },

    campaignEditorDialog (value) {
      if (!value) {
        if (!this.formIsEdit) {
          if (this.$route.name !== 'Campaigns')
            this.$router.push('/Optimization/Campaigns')
        }
        this.formIsEdit = false
        this.isPersistentEditor = false
        this.resetFormCampaignId()
        this.campaignId = null
        this.$router.push('/Optimization/Campaigns')

        document.title = 'Optimization'
      }
    },

    page () {
      this.getDataFromApi()
    },

    refreshOptimizationTable (value) {
      if (value) {
        this.getDataFromApi()
      }
    },

    locale (value) {
      this.setLocale(value)
    },

    formName (value) {
      if (!this.newForm && !this.onModeration)
        this.formNameNeedUpdate = !(value === this.formNameOrigin)
    },

    formCreator (value) {
      if (!this.newForm && !this.onModeration)
        this.formCreatorNeedUpdate = !(value === this.formCreatorOrigin)
    },

    formCreatorPercent (value) {
      if (!this.newForm && !this.onModeration)
        this.formCreatorPercentNeedUpdate = !(value === this.formCreatorPercentOrigin)
    },

    formWhoAdd (value) {
      if (!this.newForm && !this.onModeration)
        this.formWhoAddNeedUpdate = !(value === this.formWhoAddOrigin)
    },

    formWhoAddPercent (value) {
      if (!this.newForm && !this.onModeration)
        this.formWhoAddPercentNeedUpdate = !(value === this.formWhoAddPercentOrigin)
    },

    $route: {
      handler () {
        // console.log('$route$route$route$route: ', this.$route)
        if (this.$route.name === 'Edit') {
          if (this.$route.params.campaignId) {
            this.campaignId = this.$route.params.campaignId
          }
          this.prepareCampaignAndShowDialog(this.$route.params.campaign)
        }
        if (this.$route.name === 'Campaigns') {
          this.campaignEditorDialog = false
        }
      },
      deep: true,
    },

    formTab (value) {
      if (value === 'tab-1') {

        this.fetchHistoryForOptiOffAndAutopriceOff()
        // const reqOpti_off = {
        //   campaign_id: this.campaignId,
        //   type: 'number',
        //   name: 'opti_off'
        // }
        //
        // api.getHistoryOfFieldForCampaign(reqOpti_off).then((res) => {
        //   if (res.data.error) {
        //     window.console.log('getHistoryOfFieldForCampaign error', res)
        //     this.errorMsg = res.data.msg
        //     this.errorDialog = true
        //     return
        //   }
        //   this.stockOptionsOptiOff.series[0].data = res.data.history
        //   this.dataIsReady = true
        // }).catch((e) => {
        //   window.console.log('getHistoryOfFieldForCampaign error', e)
        // })
        //
        // const reqAutoprice_off = {
        //   campaign_id: this.campaignId,
        //   type: 'number',
        //   name: 'autoprice_off'
        // }
        //
        // api.getHistoryOfFieldForCampaign(reqAutoprice_off).then((res) => {
        //   if (res.data.error) {
        //     window.console.log('getHistoryOfFieldForCampaign error', res)
        //     this.errorMsg = res.data.msg
        //     this.errorDialog = true
        //     return
        //   }
        //   this.stockOptionsAutopriceOff.series[0].data = res.data.history
        //   this.dataIsReady = true
        // }).catch((e) => {
        //   window.console.log('getHistoryOfFieldForCampaign error', e)
        // })

      }
      // console.log('formTab', value)
    },

    optimizationTableMassActionStatus (value) {
      this.massActionsDialog = value
    },

    selected: {
      deep: true,
      handler (value) {

        if (Array.isArray(value)) {
          if (value.length === 0) {
            this.optimizationTableMassActionAllow = false
          } else {
            console.log('value', value)
            this.optimizationTableMassActionAllow = true
          }

        }

      }
    }
  }
}
</script>

<style scoped>

.hidden .v-icon {
  visibility: hidden
}

.hidden:hover .v-icon {
  visibility: visible
}

.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--headerHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}

/* set class="full-height-tab" */
/*.v-tabs-items.full-height-tab .v-window-item {*/
/*  height: calc(100vh - 560px); !* Adjust 270px to suits your needs *!*/
/*  !*overflow-y: auto;*!*/
/*}*/

</style>